@import "modules/variables.scss";
@import "modules/mixins.scss";

.real-time-vacation-block {
    padding: 11px;
    width: 24%;

    .purple {
        background-color: $vacationColor;
        color: $white;
        padding: 0 3px;
        font-size: 16px;
    }

    .real-time-cp-count {
        font-weight: 600;
        margin-right: 10px;
        // width: 33%;
        display: inline-block;
        text-align: center;
        font-size: 12px;
        margin-bottom: 5px;
    }

    .real-time-rtt-count {
        // margin-right: 35px;
        // width: 33%;
        font-weight: 600;
        display: inline-block;
        text-align: center;
        font-size: 12px;
    }

    .real-time-remainder-count {
        // margin-right: 35px;
        // width: 33%;
        font-weight: 600;
        display: inline-block;
        text-align: center;
        font-size: 12px;
        margin-bottom: 5px;
    }

    .cegid-vacation-count-btn {
        display: inline-block;
        text-align: center;
        cursor: pointer;
        width: 33%;
    }

    .absence-history {
        cursor: pointer;

        > i {
            font-size: 20px;
        }
    }

    .validated-level {
        color: $greyDark;
        font-size: 12px;
    }

    .counts {
        width: 60%;
        float: left;

        .counter-block {
            border: 1px solid $blueSynchrone;
        }

        .counter-line {
            margin-bottom: 10px;
            padding-left: 10px;

            .pay-manager-header {
                margin-bottom: 5px;
            }

            .manager-header {
                margin-bottom: 5px;
            }
        }
    }

    .actions {
        float: left;
        margin-left: 25px;
    }
}
