@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.category-label {
    color: $documentsColor;
    font-size: 15px;
    font-weight: 700;
    height: 36px;
    line-height: 36px;
    border-bottom: 4px solid $documentsColor;
    position: relative;
}
