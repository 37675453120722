@import "modules/variables.scss";

.home-app {
    //color: $blueSynchrone;
    //padding-top: 45px;
    //height: 150px;
    //width: 150px;
    //background-color: #fff;
    //border-radius: 8px;
    //text-align: center;
    //font-family: $mainFont;
    //font-size: 14px;
    //margin-bottom: 10px;

    color: #000;
    background: #fff;
    width: 28vw;
    aspect-ratio: 1;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    text-decoration: none;
    font-size: 4vw;

    &[disabled] {
        background: rgba(#ffffff, 0.65);
        pointer-events: none;
    }

    .icon {
        height: 60%;
        display: flex;
        align-items: center;

        .fal {
            margin-top: 10px;
            font-size: 32px;
        }
    }

    .label {
        height: 30%;
        font-size: 15px;
        display: flex;
        align-items: center;
        text-align: center;
    }

    .subtext {
        font-weight: 100;
        font-size: 12px;
    }

    //.home-app-subtext {
    //    font-weight: 100;
    //    font-size: 12px;
    //}
    //
    //.home-app-content {
    //    font-size: 20px !important;
    //}
    //
    //i {
    //    font-size: 42px;
    //    margin-bottom: 15px;
    //}
}

.home-app-btn {
    color: currentColor;
    position: absolute;
    display: block;
    width: 68%;
    border: 1px solid currentColor;
    margin: 0 16%;
    font-size: 15px;
    font-weight: 600;
    bottom: 15px;
    height: 45px;
    line-height: 43px;
    text-align: center;
    border-radius: 30px;
    text-decoration: none;
}
