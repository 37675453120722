@import "modules/variables.scss";

$lineHeight: 20px;

header {
    position: fixed;
    top: 0;
    background-color: #fff;
    color: black;
    height: $headerHeight;
    line-height: $headerHeight;
    width: 100%;
    z-index: 999;
    // @include box-shadow(0px 3px 5px rgba(0, 0, 0, 0.35));
}

.header-box-shadow {
    box-shadow: 0 3px 10px #f3f3f3;
}

.logo-synchrone {
    float: left;
    width: 200px;
    margin-left: 45px;
    height: 90px;
    background: transparent url("/logo-my-synchrone.svg") center center
        no-repeat;
}

.logo-contractor {
    float: left;
    width: 200px;
    margin-left: 45px;
    height: 90px;
    background-image: url("/logo-contractor.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 15px;
}

.user-block {
    color: $blueSynchrone;
    text-align: right;
    float: right;
    // line-height: $lineHeight;
    height: 90px;
    // margin-top: (($headerHeight - $lineHeight * 2) / 2);
    margin-right: 100px;

    a {
        color: $blueSynchrone;
        text-decoration: none;
    }
}

.user-identity {
    font-size: 14px;
    font-weight: 700;
}

.pointer {
    cursor: pointer;
}

.administration-link {
    color: $blueSynchrone;
    float: right;
    margin-right: 50px;
    font-size: 15px;
    font-weight: 700;
    width: 180px;
    padding: 0 20px;
    background-color: $adminHeaderColor;
    border-radius: 5px;
    height: 60px;
    line-height: 60px;
    position: relative;
    top: 15px;
}
