@import "modules/variables.scss";
@import "modules/mixins.scss";

.validate-overtime-lb {
    line-height: initial;
    text-align: left;
    width: 1200px;
    padding-bottom: 25px;

    .validate-overtime-header {
        padding: 0 5px 0 25px;
        height: 75px;
        background-color: $duckColor;
        color: white;
        width: 100%;

        .validate-overtime-title {
            float: left;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 75px;
            // width: 72%;
            font-size: 16px;
            letter-spacing: 0.13px;
        }

        .close-lb {
            @include font-awesome("\f4a6");
            cursor: pointer;
            color: $white;
            float: right;
            margin-right: 15px;
            margin-top: 15px;
            font-size: 40px;
        }

        .validate-overtime-identity-block {
            float: right;
            padding-right: 10px;
            padding-top: 7px;

            .validate-overtime-identity {
                font-size: 15px;
                font-weight: 700;
            }

            .validate-overtime-person-type {
                display: inline-block;
                width: 60px;
                color: $blueSynchrone;
                background-color: #e9e8e9;
                border-radius: 9px;
                font-size: 9px;
                text-align: center;
                font-weight: 600;
                letter-spacing: 0.08px;
                line-height: 7.4px;
                margin-right: 5px;
            }

            .validate-overtime-matricule {
                display: inline-block;
            }

            .validate-overtime-matricule,
            .validate-overtime-manager {
                font-weight: 100;
                font-size: 13px;
            }

            .fa-stack {
                font-size: 12px;

                .inside-icon {
                    font-size: 14px;
                }
            }
        }
    }

    .validate-overtime-period {
        text-transform: capitalize;
        padding: 15px 25px;
        font-size: 20px;
        font-weight: 700;
    }

    .all-overtime-container {
        max-height: 75vh;
        overflow: scroll;

        .overtime-table {
            border-collapse: inherit;
            border-spacing: 0 10px;
            width: 100%;
        }

        > .availability-container,
        > .intervention-container {
            margin-top: 25px;
            padding-inline: 25px;
            @include pie-clearfix();

            > .validate-overtime-container-title {
                background-color: $hoursNotWorked;
                font-weight: 700;
                margin-bottom: 15px;
                width: 150px;
                text-align: center;
                padding: 5px 0;
                border: 1px solid $blueSynchrone;
            }

            > .validate-extra-activity-container-title {
                background-color: $hoursNotWorked;
                font-weight: 700;
                margin-bottom: 15px;
                width: 250px;
                text-align: center;
                padding: 5px 0;
                border: 1px solid $blueSynchrone;
            }

            .overtime-line {
                td {
                    vertical-align: top;
                }
            }

            .overtime-table-header {
                color: #9b9b9b;
                font-weight: 600;
                font-size: 14px;
            }

            .header {
                text-align: center;
            }

            .header-label {
                min-width: 280px;
                text-align: left;
            }

            .availability-header {
                width: 380px;
            }

            .intervention-header {
                width: 500px;
            }

            .header-date,
            .header-start-at,
            .header-end-at,
            .header-hour-amount,
            .header-total-hour,
            .header-total-constraint,
            .header-ticket-amount,
            .header-total-ticket {
                text-align: center;
            }

            .header-date {
                width: 75px;
            }

            .header-start-at,
            .header-end-at,
            .header-hour-amount,
            .header-total-hour {
                width: 65px;
            }

            > .validate-overtime-container-header {
                font-weight: 600;
                font-size: 14px;
                color: #9b9b9b;
                margin-top: 10px;
            }

            .overtime-validation-comment {
                resize: none;
                width: 250px;
                height: 50px;
                font-family: $mainFont;
            }

            .overtype-edit-type__menu {
                margin-top: 0;
            }

            .overtime-line {
                .overtime-label {
                    font-size: 14px;
                    padding-right: 20px;
                }

                .overtime-date,
                .overtime-start-at,
                .overtime-end-at,
                .overtime-hour-amount,
                .overtime-total-hour,
                .overtime-total-constraint,
                .overtime-ticket-amount,
                .overtime-total-ticket {
                    font-size: 14px;
                    text-align: center;
                }

                .overtime-comment {
                    font-size: 12px;
                    text-align: left;
                }

                .overtime-actions {
                    text-align: center;
                    font-size: 26px;

                    .close-edit-overtime {
                        color: $notifierErrorColor;
                        margin-right: 10px;
                        cursor: pointer;
                    }

                    .validate-edit-overtime {
                        color: $notifierSuccessColor;
                        cursor: pointer;
                    }

                    .edit-overtime-icon {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
