@import "modules/variables.scss";
@import "modules/mixins.scss";

.parameters-page {
    justify-content: flex-start;
    padding: 0 25px 20px 25px;
    background-color: #f7f5f8 !important;

    .page-header {
        transform: translateX(-25px);
        width: calc(100% + 50px);
        //box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    }
}

.parameters-container {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .parameters-menu {
        width: 20%;

        @include onMobile {
            width: 100%;
            height: calc(100vh - 100px);
        }

        .parameters-menu-entry {
            display: block;
            padding: 10px;
            cursor: pointer;
            font-weight: 500;
            color: inherit;

            &:hover {
                text-decoration: underline;
                text-underline-offset: 2px;
            }

            @include onMobile {
                font-size: 18px;

                i {
                    font-size: 24px;
                    width: 50px;
                    text-align: center;
                }
            }
        }

        .parameters-menu-entry-active {
            background-color: $green;
            color: white;

            &:hover {
                text-decoration: none;
            }

            @include onMobile {
                background-color: inherit;
                color: inherit;
            }
        }
    }

    .parameters-content {
        width: 79%;
        padding: 10px;

        @include onMobile {
            display: none;
        }
    }
}

.parameters-title {
    font-size: 30px;
    margin: 20px 0;
}

.parameters-separator {
    height: 1px;
    background-color: #e4e4e7;
    margin: 20px 0;

    @include onMobile {
        display: none;
    }
}

.parameters-card {
    max-width: 1200px;
    margin: auto;
    background-color: #fff;

    @include onMobile {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;

        + .parameters-card {
            margin-top: 30px;
        }

        .field-container {
            width: 100%;
            position: relative;

            input {
                font-size: 15px;
                padding: 5px 0;
                width: 100%;
            }
        }
    }
}

.parameters-card-title {
    font-weight: 600;
    font-size: 16px;
    margin: 30px 0 20px 0;

    @include onMobile {
        text-transform: uppercase;
        border-bottom: none;
        margin: 10px 10px 0 10px;
        font-weight: 300;
        font-size: 14px;
        padding: 10px 0;
        color: #71717a;
    }
}

.parameters-card-description {
    border-bottom: none;
    margin: 0 10px 15px 10px;
    font-weight: 300;
    font-size: 14px;
    padding: 10px 0;
    color: #71717a;
}

.parameters-category-container {
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    //margin-bottom: 25px;
    text-align: left;
    width: 100%;

    @include onMobile {
        background-color: transparent;
    }

    .parameters-category-title {
        border-radius: 10px 10px 0 0;
        color: #fff;
        background-color: $green;
        font-size: 24px;
        height: 50px;
        line-height: 50px;
        font-weight: 700;
        padding: 0 20px;
    }

    .page-header {
        font-weight: 600;
        font-size: 20px;
        padding: 0;
    }

    .parameters-subcategory-container {
        .parameters-subcategory-title {
            font-weight: 600;
            font-size: 20px;
        }

        .parameters-subcategory-subtitle {
            padding: 10px 0;
            color: #71717a;
            border-bottom: 1px solid #e4e4e7;
            font-size: 15px;
            font-weight: 400;

            @include onMobile {
                padding: 20px 0px 0px 0;
                color: #000;
                border-bottom: none;
            }
        }

        .parameters-subcategory-content {
            //padding-top: 15px;
        }
    }

    .parameters-checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked + .parameters-checkmark:after {
            display: block;
        }
    }

    .parameters-checkbox-label {
        text-transform: capitalize;
    }

    .parameters-checkmark {
        height: 20px;
        width: 20px;
        display: inline-block;
        position: relative;
        top: 5px;
        border: 1px solid black;
        padding: 3px;
        margin-right: 10px;

        &:after {
            content: "";
            position: relative;
            display: none;
            width: 12px;
            height: 12px;
            background-color: $menuColor;
        }
    }
}

.parameters-buttons-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;

    .parameters-buttons-confirm {
        color: $white;
        background-color: $notifierSuccessColor;
        width: 240px;
        height: 40px;
        line-height: 40px;
        border-radius: 50px;
        cursor: pointer;
        border: none;
        text-align: center;
    }
}
