@import "modules/variables.scss";
@import "modules/mixins.scss";

.dropdown {
    position: absolute;
    left: calc(98% - 20px);
    top: 0;
    right: 0;
    height: 60px;

    > .dropdown-btn {
        cursor: pointer;
    }

    > .dropdown-list {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: visibility 0s, top 0.25s, opacity 0.25s;
        transition: visibility 0s, top 0.25s, opacity 0.25s;
        list-style: none;
        background-color: white;
        color: #17313a;
        position: relative;
        top: -40px;
        border: 1px solid lightgrey;
        padding: 0;

        > li {
            line-height: 14px;
            font-size: 14px;
            font-weight: normal;
            padding: 10px 15px;
            cursor: pointer;

            &:hover {
                background-color: rgba(122, 209, 224, 0.5);

                &.disabled {
                    background-color: initial;
                    cursor: not-allowed;
                }
            }
        }
    }
}

.dropdown-open {
    right: unset;

    > .dropdown-list {
        z-index: 9999;
        visibility: visible;
        opacity: 1;
        white-space: nowrap;
    }
}
