@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.psycho-item {
    background-color: #f0f9f8;
    font-size: 12px;
    width: 98%;
    margin: 0 auto;
    padding: 0 12px;

    .label-item {
        color: $documentsColor;
        font-size: 15px;
        font-weight: 600;
        width: 50%;
        display: inline-block;
    }

    .phone-item {
        width: 50%;
        display: inline-block;

        i {
            font-size: 15px;
            width: 25px;
            transform: rotateY(180deg);
            margin-right: 4px;
        }
    }
}

.psycho-subtitle {
    margin-left: 7px;
}
