@import "modules/variables.scss";
@import "modules/mixins.scss";

.documents-container {
    @include onMobile {
        margin: 15px
    }
}

#union-container {


    > .links-container {
        display: flex;

        > .title {
            height: 70px;
            align-items: flex-end;
            display: flex;
            font-size: 32px;
            margin-bottom: 30px;
            color: $blueSynchrone;
        }

        > .link-to-union {
            font-size: 24px;
            height: 70px;
            align-items: flex-end;
            display: flex;
            color: $blueSynchrone;
        }

        > .links-separator {
            margin: 0 13px;
            font-size: 24px;
            height: 70px;
            align-items: flex-end;
            display: flex;
            color: $blueSynchrone;
        }

        .active {
            font-size: 24px !important;
            color: $documentsColor !important;
            font-weight: 700;
        }
    }

    > .documents-tabs-container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .union-documents-btn,
        .synchrone-documents-btn {
            border-radius: 5px;
            width: 250px;
            cursor: pointer;
            text-align: center;
            font-weight: 700;
            padding: 15px 0;
            font-size: 16px;
        }

        .synchrone-documents-btn {
            color: $documentsColor;
        }

        .synchrone-documents-btn.active {
            background-color: $documentsColor;
        }

        .union-documents-btn {
            color: #a6b9c4;
        }

        .union-documents-btn.active {
            background-color: #a6b9c4;
        }

        .active {
            color: white;
        }
    }
}

.files-container {
    .files-title {
        color: $documentsColor;
        font-size: 15px;
        font-weight: 700;
        height: 36px;
        line-height: 36px;
        border-bottom: 4px solid $documentsColor;
        position: relative;
    }

    .empty-unions-files {
        margin-top: 15px;
    }

    .file-row {
        color: $blueSynchrone;
        display: block;
        font-size: 15px;
        font-weight: 600;
        background-color: white;
        position: relative;
        height: 60px;
        line-height: 60px;
        padding: 0 16px;

        // &:first-of-type {
        //     &:not(.nested) {
        //         border-top: 4px solid $green;
        //     }
        // }

        .file-row-label {
            display: inline-block;
            width: calc(94% - 10px);
            margin-left: 10px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            vertical-align: bottom;
            white-space: nowrap;
        }

        .download-icon {
            display: inline-block;
            font-size: 24px;
            transform: rotate(180deg);
            color: $documentsColor;

            @include onMobile {
                display: none;
            }
        }
    }

    .hovered {
        background-color: rgba(122, 209, 224, 0.5);
        cursor: pointer;
    }
}

.union-documents-btn {
    width: 100%;
    height: 62px;
    border-radius: 100px;
    background-color: $documentsColor;
    color: $white;
    display: block;
    line-height: 62px;
    margin-top: 15px;
    font-size: 24px;
    font-weight: 700;
    padding: 0 25px;
    float: right;
    position: relative;

    i {
        font-size: 43px;
        position: absolute;
        top: 10px;
        right: 25px;
    }
}

.line-container {
    width: 100%;

    //@include onMobile {
    //    width: 100%;
    //}

    &:not(:last-of-type) {
        border-bottom: 1px solid lightgrey;

        &:not(.nested) {
            > .line-container {
                border-bottom: none !important;
            }
        }
    }

    &:last-of-type {
        &:not(.nested) {
            border-radius: 0 0 8px 8px;
        }

        > .line-container {
            border-radius: 0 !important;
        }
    }
}
