@import "modules/mixins.scss";

.contact-group {
    width: 400px;
    height: 100px;
    line-height: 100px;
    background: #fff;
    padding: 0 10px;
    margin-bottom: 15px;
    margin-right: 75px;
    cursor: pointer;

    @include onMobile {
        display: flex;
        height: auto;
        width: 100%;
        box-shadow: 3px 3px 10px #e0e0e0;
        line-height: 80px;
        font-size: 16px;
    }
}

.contact-images {
    display: inline-block;
    img {
        width: 84px;
        height: 84px;
        border-radius: 50px;
        vertical-align: middle;
        margin-right: 10px;
        border: 4px solid #fff;

        + img {
            margin-left: -40px;
        }
    }

    @include onMobile {
        img {
            width: 68px;
            height: 68px;
            border-radius: 34px;
        }
    }
}

.contact-identities {
    vertical-align: top;
    display: inline-flex;
    flex-direction: column;
    font-size: 13px;
    height: 100px;
    line-height: 1.6;
    justify-content: center;

    @include onMobile {
        height: auto;
        font-size: 16px;
    }
}
