.days-of-week {
    display: flex;
    line-height: 43px;

    .day-of-week {
        width: 169px;
        display: block;
        margin: 1px;
        text-align: center;
        color: #4a4a4a;
        font-family: "Open Sans";
        font-size: 12px;
    }
}
