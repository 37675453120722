@import "modules/variables.scss";
@import "modules/mixins.scss";

h1 {
    margin: 25px auto;
    width: 1200px;
}

.empty-runas {
    font-style: italic;
    margin-left: 25px;
}
