@import "modules/variables.scss";
@import "modules/mixins.scss";

.timesheet-lb {
    background-color: $timesheetColor;
}

.extra-weeks-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding-block: 10vw;
}

.extra-week {
    background-color: $green;
    color: $white;
    width: 80vw;
    height: 40px;
    line-height: 40px;
    text-align: center;

    &.disabled {
        opacity: .5;
        color: white !important;
    }
}

.extra-request {
    p {
        background-color: #fff;
        padding: 15px;
        text-align: justify;
        margin: 0 20px 30px 20px;
        font-size: 14px;
        color: #000;
    }
}

.lb-confirm-send-request {
    color: #fff;
    display: flex;
    align-items: center;
    background-color: #27b19f;
    height: 50px;
    line-height: 1.2;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    font-size: 14px;
    text-align: center;
    padding-inline: 10px;
}
