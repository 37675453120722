@import "modules/variables.scss";
@import "modules/mixins.scss";

.lb-w1200 {
  .lb-ask-extra-activity {
    line-height: initial;
    height: initial;
    text-align: left;

    .lb-ask-extra-activity-header {
      display: flex;
      height: 70px;
      //line-height: 70px;
      align-items: center;
      width: 100%;
      background-color: $duckColor;
      color: $white;
      text-transform: uppercase;
      padding: 0  26px;
      position: relative;

      .ask-extra-activity-header-title {
        position: absolute;
        font-weight: 700;
      }

      .ask-extra-activity-month-header {
        position: absolute;
        display: inline-block;
        left: calc(50% - 50px);

        .month {
          font-weight: 700;
        }

        .year {
          font-weight: 400;
        }
      }

      .close-lb {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 42px;
        cursor: pointer;
      }
    }

    .lb-ask-extra-activity-content {
      margin: 20px;
      text-align: center;

      .warning-message {
        color: $corail;
      }
    }

  }
}
