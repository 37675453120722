@import "modules/variables.scss";

.send-month-btn {
    color: $white;
    width: 250px;
    height: 44px;
    background-color: $inactiveSendMonth;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    //line-height: 37px;
    letter-spacing: 0.4px;
    border-radius: 50px;
    margin-top: 10px;
    //display: inline-block;
    cursor: not-allowed;
    font-weight: 700;

    &.active {
        cursor: pointer;
        background-color: $duckColor !important;
    }
}
