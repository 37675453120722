@import "modules/variables.scss";
@import "modules/mixins.scss";

.overtime {
    &:hover {
        height: 25px;
    }

    .overtime-count {
        line-height: 15px;
        height: 15px;
        float: left;
        margin-right: 5px;
    }

    .overtime-description {
        float: left;
        line-height: 15px;
        height: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: calc(100% - 60px);
    }
}
