@import "modules/variables.scss";
@import "modules/mixins.scss";

#change-personal-email-container {
    //width: 40%;
    //padding: 20px 0;
    //margin: 0 auto 30px auto;
    //background-color: white;
    color: $blueSynchrone;
    //border-top: 1px solid #e4e4e7;

    .form-change-personal-email {
        display: flex;

        @include onMobile {
            flex-direction: column;
            align-items: center;
        }
    }

    .form-change-personal-email-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 25px;
    }

    .form-change-personal-email-helper-text {
        font-size: 13px;
        //font-weight: 600;
        //margin-bottom: 35px;
    }

    .disabled-account-btn {
        opacity: 0.6;
        cursor: not-allowed !important;
    }
}
