@import "modules/variables.scss";

.calendar-mobile {
    --remote-color: #000;

    background-color: #fff;
    aspect-ratio: 1.125;

    .days-container-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        gap: 5%;
        padding: 15px;
    }

    .day-mobile {
        position: relative;
        width: 10%;
        aspect-ratio: 1;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5%;
        border: 3px solid transparent;

        &.remote-day-mobile {
            border-width: 3px;
        }

        &.unavailable {
            //border: none;
            opacity: 0.25;

            &.remote-day-mobile {
                opacity: 1;
            }
        }

        &.autofillable {
            animation: fadeInDay 0.5s forwards ease-in-out;
        }

        &.selected {
            opacity: 0.5;
        }

        &.not-current-month {
            border: none;
            opacity: 0.5;
        }

        &.day-remote-am,
        &.day-remote-full {
            border-left: 3px solid var(--remote-color);
            border-top: 3px solid var(--remote-color);
        }

        &.day-remote-pm,
        &.day-remote-full {
            border-right: 3px solid var(--remote-color);
            border-bottom: 3px solid var(--remote-color);
        }

        .day-number {
            animation: fadeIn 0.5s forwards ease-in-out;
            position: absolute;
            width: 100%;
            height: 100%;
            margin: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            font-size: 16px;

            &.day-has-extra {
                &:before {
                    content: "";
                    position: absolute;
                    background-color: $hoursNotWorked;
                    border-radius: 3px;
                    width: 6px;
                    height: 6px;
                    right: 1px;
                    bottom: 1px;
                }
            }
        }

        &.day-mobile-validated {
            opacity: 1 !important;

            .day-number {
                animation: fadeInDayValidated 0.5s forwards ease-in-out;
            }
        }

        .remote-hd-abs {
            position: absolute;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            border-style: solid;
            border-width: 0;
            border-radius: 3px;
            background-image: -webkit-linear-gradient(-45deg, var(--remote-abs-color-am, transparent) 50%, var(--remote-abs-color-pm, transparent) 50%);
            opacity: 0.5;
            color: transparent;

            &:before {
                content: attr(data-value);
                position: absolute;
                width: calc(100%);
                height: calc(100%);
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: -webkit-linear-gradient(-45deg, var(--remote-abs-font-color-am, #000) 50%, var(--remote-abs-font-color-pm, #000) 50%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }


            &.remote-hd-abs-am {
                --remote-abs-color-am: #{$vacationColor};
                --remote-abs-font-color-am: #fff;
                border-left-width: 0;
                border-top-width: 0;
            }

            &.remote-hd-abs-pm {
                --remote-abs-color-pm: #{$vacationColor};
                --remote-abs-font-color-pm: #fff;
                border-right-width: 0;
                border-bottom-width: 0;

                &.remote-hd-abs-am {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .day-hd {
            opacity: 0;
            animation: fadeIn 0.5s forwards ease-in-out;
            position: absolute;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            border-style: solid;
            border-width: 0;
            border-radius: 3px;
            border-color: transparent;
            background-image: -webkit-linear-gradient(-45deg, var(--am-color-validated, transparent) 50%, var(--pm-color-validated, transparent) 50%);

            &.day-hd-am-sickness,
            &.day-hd-pm-sickness {
                background-color: #{$vacationColor};
                background-image: url(/img/sickness.png);
                background-position: right center;
                //border: none;
            }

            &.day-hd-am-validated {
                --am-color-validated: var(--am-color);
            }

            &.day-hd-pm-validated {
                --pm-color-validated: var(--pm-color);
            }

            &.day-hd-am-activity {
                --am-color: #{$activityColor};
            }

            &.day-hd-pm-activity {
                --pm-color: #{$activityColor};
            }

            &.day-hd-am-absence,
            &.day-hd-am-sickness {
                --am-color: #{$vacationColor};
            }

            &.day-hd-pm-absence,
            &.day-hd-pm-sickness {
                --pm-color: #{$vacationColor};
            }

            &.day-hd-am {
                border-left-width: 3px;
                border-top-width: 3px;
                border-left-color: var(--am-color, transparent);
                border-top-color: var(--am-color, transparent);
            }

            &.day-hd-pm {
                border-right-width: 3px;
                border-bottom-width: 3px;
                border-right-color: var(--pm-color, transparent);
                border-bottom-color: var(--pm-color, transparent);
            }

        }
    }

    .days-of-week {
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        flex-wrap: nowrap;
        width: 100%;
        gap: 5%;
        line-height: 1;

        .day-of-week {
            width: 10%;
            aspect-ratio: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 3%;
        }
    }

    // Nombre total de jours (par exemple 30 jours)
    $days: 42; // 6 semaines complètes
    $delay: 15ms;

    @for $i from 1 through $days {
        .day-mobile:nth-child(#{$i}) {
            animation-delay: $delay * ($i - 1);

            .day-number {
                animation-delay: $delay * ($i - 1);
            }

            .day-hd {
                animation-delay: $delay * ($i - 1);
            }
        }
    }

    // Animation keyframes
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }

    @keyframes fadeInDay {
        from {
            border: 3px solid transparent;
            color: #000;
        }
        to {
            border: 3px solid #c7c6c6;
        }
    }

    @keyframes fadeInDayValidated {
        from {
            color: #000;
        }
        to {
            color: #fff;
        }
    }
}


