@import "modules/variables.scss";
@import "modules/mixins.scss";

.activity-block {
    border-right: 2px solid #f2f2f2;
    width: 30%;
    padding: 11px;
    font-size: 13px;

    > .activity-line {
        margin-bottom: 20px;

        &.not-validated {
            > .activity-description {
                font-style: italic;
                font-weight: 700;
                color: $inactiveSendMonth;
                display: inline-block;
            }

            > .validate-btn {
                display: inline-block;
                background-color: $notifierSuccessColor;
                color: $white;
                border-radius: 15px;
                width: 133px;
                font-weight: 700;
                text-align: center;
                font-size: 12px;
                position: relative;
                right: -90px;
                cursor: pointer;
            }
        }
    }

    .no-prestation {
        color: $corail;
        font-weight: bold;
    }
}
