@import "modules/variables.scss";
@import "modules/mixins.scss";

.lightbox {
    background-color: #fff;
    width: 70%;
    margin: auto;

    &.animate {
        transition: bottom 250ms;
    }

    .lb-header {
        position: relative;
        user-select: none;

        @include onMobile {
            &:before {
                content: "";
                position: absolute;
                display: block;
                width: 40px;
                height: 4px;
                border-radius: 2px;
                background-color: #fff;
                z-index: 1500;
                transform: translateX(-50%);
                top: 10px;
                left: 50%;
            }
        }

    }

    @include onMobile {
        .close-lb {
            display: none;
        }
    }
}

.lb-centered {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
}

.lb-w1200 {
    margin-top: 100px;
    width: 1200px !important;
    min-height: 280px;
    margin-bottom: 100px;

    @include onDesktopLittle {
        overflow: auto;
        //max-height: 500px;
    }

    @include onMobile {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        padding: 0;
        margin: 0;
        height: 97vh;
        height: 97svh;
        //overflow: hidden;
        border-radius: 22px 22px 0 0;
        overflow-y: auto;
        overflow-x: hidden;
        animation: rollout 0.4s;
        @keyframes rollout {
            0% {
                transform: translateY(100px);
            }
            100% {
                transform: none;
            }
        }
    }
}

.lb-mw50 {
    min-width: 50%;
    max-width: 1000px;
}

.confirm-message {
    margin: 20px 0;
}
