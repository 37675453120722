@import "modules/variables.scss";
@import "modules/mixins.scss";

.absence-container {
    margin-top: 90px;

    .insufficient-vacations-icon {
        float: right;
        color: $notifierErrorColor;
        font-size: 23px;
        position: relative;
        right: 9px;
        top: 4px;
    }

    .no-absence {
        text-align: center;
        margin-top: 25px;
        width: 1200px;
        padding-top: 40px;
        margin-left: 90px;
    }

    .absence-list {
        margin-left: 80px;
        padding-left: 40px;

        .absence-list-title {
            padding: 20px 0;
            font-size: 32px;
        }

        .absence-list-header {
            width: 1200px;
            color: #9b9b9b;

            .header-identity {
                display: inline-block;
                width: 23%;
                padding: 12px;
            }

            .header-absence-type {
                display: inline-block;
                width: 26%;
                padding: 12px;
            }

            .header-days-amount {
                display: inline-block;
                width: 5%;
                padding: 12px;
            }

            .header-actions {
                display: inline-block;
                width: 22%;
                padding: 12px;
                vertical-align: top;

                .validation-level {
                    font-size: 14px;
                    font-weight: 700;
                    color: $blueSynchrone;
                    width: 50px;
                    display: inline-block;
                    text-align: center;
                }
            }

            .header-vacation-count {
                display: inline-block;
                width: 24%;
                padding: 12px;
            }
        }
    }

    > .page-controls {
        text-align: center;
        width: 1200px;
        margin-left: 120px;

        > .page-number {
            display: inline-block;
            margin: 0 15px;
        }

        > .next-page,
        > .previous-page {
            cursor: pointer;
            display: inline-block;
        }
    }
}
