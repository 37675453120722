@import "modules/variables.scss";
@import "modules/mixins.scss";

#change-password-container {
    //width: 40%;
    //padding: 20px 0;
    //margin: 0 auto 30px auto;
    //background-color: white;
    //color: $blueSynchrone;
    //border-top: 1px solid #e4e4e7;

    #form-change-password-container {
        form {
            display: grid;
            //grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-template-areas:
                'form instructions'
                'button button';
            gap: 0;

            .form-inputs {
                grid-area: form;
                width: 100%;

                .field-container {
                    width: 100%;
                }
            }
            .validation-instructions {
                grid-area: instructions;
            }

            .parameters-buttons-container {
                button {
                    margin: 0;
                }
                grid-area: button;
            }
        }

        @include onMobile {
            form {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .disabled-account-btn {
        opacity: 0.6;
        cursor: not-allowed !important;
    }

    .form-change-password-title {
        font-size: 13px;
        margin-bottom: 25px;
        font-weight: 600;
    }

    #change-password-hidden-indicator {
        position: relative;
        right: 20px;

        @include onMobile {
            position: absolute;
            right: 5px;
            top: 5px;
        }
    }

    .password-valid {
        color: $notifierSuccessColor;
        position: absolute;
        right: calc(50% - 150px);
        font-size: 18px;
    }

    .validation-instructions {
        background-color: $autofilled;
        font-size: 14px;
        padding: 10px 25px 20px 25px;
        color: $blueSynchrone;

        @include onMobile {
            font-size: 13px;
            padding: 1px 15px 15px 15px;

            + .modify-account-action {
                margin-bottom: 0;
            }
        }

        .validation-instruction-header {
            text-align: left;
            font-size: 14px;
            font-weight: 600;
            margin: 15px 0;

            @include onMobile {
                font-size: 13px;
            }
        }

        .validation-instruction {
            text-align: left;
            line-height: 22px;
        }

        .validation-icon {
            color: $eventCodeDescription;
            font-size: 18px;
        }

        .validated {
            > .validation-icon {
                color: $notifierSuccessColor;
            }
        }
    }
}
