@import "modules/variables.scss";
@import "modules/mixins.scss";

.backend-container {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    top: 125px;

    > .backend-container-header {
        // color: $menuColor;
        // font-weight: 700;
        margin-left: 80px;
        font-size: 22px;
        font-family: "Open Sans", sans-serif;
    }

    > .backend-container-links {
        margin: 15px 80px 0 80px;
        display: flex;
        justify-content: center;

        > .backend-container-links-list {
            float: left;
            width: 450px;
            margin-right: 150px;

            > .backend-container-links-list-title {
                font-weight: 700;
                font-size: 22px;
                margin-bottom: 10px;

                i {
                    font-size: 32px;
                }
            }

            > .backend-container-links-list-content {
                > .backend-link {
                    background-color: #fff;
                    display: block;
                    color: $blueSynchrone;
                    padding: 15px 0 15px 45px;
                    border-bottom: 2px solid $adminHeaderColor;

                    &:first-of-type {
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }

                    &:last-of-type {
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        border-bottom: none;
                    }

                    &:hover {
                        background-color: $adminHeaderColor;
                        font-weight: 900;
                    }
                }
            }
        }

        // > .backend-action {
        //     border: 2px solid $blueSynchrone;
        //     border-radius: 54px;
        //     width: 380px;
        //     height: 70px;
        //     line-height: 70px;
        //     margin: 0 25px;
        //     text-align: center;
        //     display: inline-block;
        //     color: $blueSynchrone;
        //     font-size: 24px;

        //     .backend-action-icon {
        //         font-size: 36px;
        //         position: relative;
        //         top: 5px;
        //         right: 20px;
        //     }
        // }
    }
}
