@import "modules/variables.scss";
@import "modules/mixins.scss";

.force-submit-lb {
    line-height: initial;
    text-align: left;
    padding: 25px;

    > .force-submit-title {
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        margin: 10px 0;
    }

    > .force-submit-to-be-locked {
        font-weight: 600;
        padding-bottom: 25px;
    }

    > .force-submit-recap {
        padding-bottom: 35px;

        > .recap-row {
            line-height: 30px;

            > .recap-label,
            > .recap-amount {
                display: inline-block;
            }

            > .recap-label {
                width: 360px;
            }

            > .recap-amount {
                width: 50px;
                padding-right: 3px;
            }

            > .users-recap-label {
                vertical-align: top;
            }
    
            > .users-recap-list {
                padding: 0;
                margin: 0;
                display: inline-block;
                list-style-type: none;
                max-height: 240px;
                overflow: auto;
                width: 40%;
            }
        }

        > .recap-total {
            margin-left: 360px;
            border-top: 1px solid;
            width: 50px;
            text-align: right;
            padding-right: 3px;
            line-height: 30px;
        }

    }

    > .force-submit-actions {
        margin-top: 20px;
        text-align: center;
        display: flex;
        justify-content: space-evenly;

        > .cancel-btn {
            // display: inline-block;
            background-color: $corail;
        }

        > .next-btn {
            // display: inline-block;
        }
    }
}
