@import "modules/variables.scss";
@import "modules/mixins.scss";

.article-type-press-release {
    color: $green;
    font-size: 22px;
}

.press-release-date {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    font-weight: $semiBold;
    margin: 10px 0;

    i {
        display: inline-block;
        font-size: 22px;
        vertical-align: middle;
        margin-right: 4px;
        margin-top: -4px;
    }
}

.press-release-download {
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    font-weight: $semiBold;
    margin: 30px 0 20px 0;
    display: block;
    color: #14313e !important;

    i {
        display: inline-block;
        font-size: 32px;
        vertical-align: middle;
        margin-right: 4px;
        font-weight: normal;
        //margin-top: -4px;

        &:before {
            content: "\f1c1";
        }
    }
}
