@import "modules/variables.scss";
@import "modules/mixins.scss";

.month-header {
    font-size: 18px;
    text-align: center;
    background-color: $duckColor;
    color: #fff;
    line-height: 40px;
    font-family: "Montserrat";
    display: inline-block;

    &.submitted {
        background-color: $inactiveSendMonth;
    }

    &.view {
        background-color: $adminHeaderColor !important;
        color: $blueSynchrone;
    }

    .left-header {
        color: #fff;
        float: left;
        margin-top: 7px;
        margin-left: 20px;
        font-size: 28px;
    }

    .right-header {
        color: #fff;
        float: right;
        margin-top: 7px;
        margin-right: 20px;
        font-size: 28px;
    }

    .month {
        font-weight: 700;
        text-transform: uppercase;
    }

    .year {
        font-weight: 400;
    }

    .user-identity {
    }
}
