@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.folder-container {
    background-color: #fff;
    padding: 0 5px;
    font-size: 15px;
    font-weight: 600;
    // height: 60px;
    line-height: 60px;
    cursor: pointer;
    position: relative;


    &:not(:last-of-type) {
        border-bottom: 1px solid #dadada;
    }

    .social-folder-row {
        background-color: #fff;
        font-size: 15px;
        font-weight: 600;
        padding: 0 16px;
        //height: 60px;
        line-height: 60px;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include onMobile {
            font-size: 4vw;
            line-height: 15vw;
        }

        .folder-label {
            display: inline-block;
            width: 94%;
            overflow-x: hidden;
            text-overflow: ellipsis;
            vertical-align: bottom;
        }

        .expand-icon {
            display: inline-block;
            font-size: 24px;
            vertical-align: middle;
            color: $documentsColor;
        }

        //.expand-icon-before {
        //    margin-right: 10px;
        //}

        .rotated-icon {
            transform: rotate(90deg);
        }
    }
}

.folder-container.closed:last-of-type {
    border-radius: 0 0 8px 8px;
}
