@import "modules/variables.scss";
@import "modules/mixins.scss";

.vacation-history {
    border-top: 3px solid $vacationColor;
    padding: 15px 0;
    margin: 0 20px;

    > .vacation-history-header {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: $blueSynchrone;
        font-family: "Open Sans";

        > .fa-stopwatch {
            font-size: 20px;
            cursor: pointer;
            float: right;
        }
    }

    > .history {
        > .no-absence {
            margin-top: 55px;
            text-align: center;
            color: #979797;
        }

        > .history-header {
            margin-top: 15px;
            margin-bottom: 8px;
            margin-left: 25px;

            > .history-header-cell {
                display: inline-block;
                font-size: 12px;
                font-weight: 600;
                font-family: "Open Sans";
                color: $blueSynchrone;
            }

            > .dates {
                width: 220px;
            }

            > .total {
                width: 40px;
            }

            > .type {
                width: 80px;
            }
        }

        .history-line {
            font-size: 14px;
            clear: both;
            margin-bottom: 10px;

            .absence-status {
                width: 25px;
                text-align: left;
                float: left;
            }

            .absence-dates {
                width: 210px;
                float: left;

                .fa-chevron-right {
                    margin: 0 5px;
                    float: left;
                }

                .absence-startAt,
                .absence-endAt {
                    width: 90px;
                    margin: 0;
                    float: left;
                }
            }

            .total {
                width: 40px;
                float: left;
                text-align: right;
                font-weight: 700;
                margin-right: 10px;
            }

            .type {
                width: 85px;
                overflow: hidden;
                text-overflow: ellipsis;
                float: left;
                white-space: nowrap;
                font-size: 12px;
                font-weight: 700;
                line-height: 22px;
            }
        }
    }
}
