@import "modules/mixins.scss";

.contact-block {
    width: 400px;
    margin-bottom: 15px;

    .contact {
        width: 100%;
        margin-bottom: 5px;
    }
}

.contact-help {
    font-size: 12px;
    letter-spacing: 0.1px;
    font-style: italic;

    @include onMobile {
        width: calc(100% - 30px);
        padding-inline: 15px;
    }
}
