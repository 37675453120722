@import "modules/variables.scss";
@import "modules/mixins.scss";

#security-popup {
    background-color: #fff;
    position: relative;
    margin: 10vh auto;
    width: 536px;
    line-height: 17px;
    text-align: left;
    font-family: 'proxima-nova';
    height: 80vh;
    border: 1px solid #E0C67B;

    #security-popup-close {
        background-color: #E0C67B;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: absolute;
        left: 510px;
        top: -24px;
        cursor: pointer;
        float: left;

        .close-icon {
            color: #152434;
            font-size: 36px;
            position: relative;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }
    }

    .security-popup-header {
        background-color: #152434;
        color: white;
        padding: 20px 40px;

        .security-popup-header-title {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 15px;
        }

        .security-popup-header-content {
            font-size: 14px;
        }
    }

    .security-popup-content-container {
        padding: 0 30px 20px 40px;
        margin-top: 20px;
        margin-right: 10px;
        overflow-y: scroll;
        height: calc(80vh - 235px);

        img {
            margin-bottom: 20px;
        }

        .security-popup-content-scrollable {

            .security-popup-content-title {
                color: #E0C67B;
                font-size: 22px;
                margin-bottom: 20px;
            }

            .security-popup-content {
                font-size: 12px;

                li {
                    margin-bottom: 10px;
                }

                a {
                    color: black;
                }
            }
        }
    }

    .security-popup-footer {
        background-image: url("/img/security/footer.png");
        height: 75px;
    }
}