@import "modules/variables.scss";
@import "modules/mixins.scss";

.cra-history-actions-lb {
    width: 1200px;
    line-height: initial;
    text-align: left;
    position: relative;

    .cra-history-actions-header {
        background-color: $duckColor;
        color: white;
        padding: 0 30px;
        height: 75px;

        .close-lb {
            @include font-awesome("\f4a6");
            cursor: pointer;
            color: $white;
            position: absolute;
            right: 10px;
            top: 5px;
            font-size: 40px;
        }

        .cra-history-actions-title {
            float: left;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 75px;
            // width: 72%;
            font-size: 16px;
            letter-spacing: 0.13px;
        }

        .cra-history-actions-identity-block {
            float: right;
            padding-right: 10px;
            padding-top: 7px;

            .cra-history-actions-identity {
                font-size: 15px;
                font-weight: 700;
            }

            .cra-history-actions-matricule {
                display: inline-block;
            }

            .cra-history-actions-matricule,
            .cra-history-actions-manager {
                font-weight: 100;
                font-size: 13px;
            }

            .fa-stack {
                font-size: 12px;

                .inside-icon {
                    font-size: 14px;
                }
            }
        }
    }

    .cra-history-actions-table-header {
        @include pie-clearfix();
        color: #9b9b9b;
        padding: 10px 30px;

        > .table-header-description {
            width: 300px;
            float: left;
        }

        > .table-header-date {
            width: 200px;
            float: left;
            padding-left: 14px;
        }

        > .table-header-author {
            width: 220px;
            float: left;
            padding-left: 14px;
        }
    }

    .cra-history-actions-content {
        padding-bottom: 30px;
        max-height: 80vh;
        overflow-y: scroll;

        .cra-history-actions-row {
            padding: 10px 30px;
            @include pie-clearfix();

            .actions-row-description {
                width: 300px;
                border-right: 1px solid #e9e8e9;
                float: left;
            }

            .actions-row-date {
                width: 200px;
                border-right: 1px solid #e9e8e9;
                float: left;
                padding-left: 14px;
            }

            .actions-row-author {
                width: 220px;
                float: left;
                padding-left: 14px;

                > .italic-label {
                    font-style: italic;
                }
            }
        }
    }
}
