@import "modules/variables.scss";
@import "modules/mixins.scss";


.extra-activities-line-mobile {
    background-color: $mobileExtraActivityValidationColor;
    padding: 10px 25px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .extra-activities-line-month {
        font-size: 15px;
        font-weight: 700;
    }
}

.validation-overlay {

    .extra-activities-validation-mobile-header {
        font-size: 18px;
        margin: 15px 0;
        font-weight: 500;
    }

    .extra-activities-validation-mobile-month {
        color: $hoursNotWorked;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
    }

    .validate-extra-activity-comment-input {
        height: 10vh;
        width: 80vw;
        margin: 25px 0;
        font-family: $mainFont;
    }

    .validate-extra-activity-comment-input::placeholder {
        font-size: 4vw;
        font-family: $mainFont;
    }
}
