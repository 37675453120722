@import "modules/variables.scss";
@import "modules/mixins.scss";

.main-navigation {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 320px;
    font-family: "Open Sans", sans-serif;
    background-color: $menuColor;
    top: $headerHeight;
    color: #fff;
    font-weight: 600;
    margin: 0;
    padding-top: 2vh;
    padding-inline-start: 0;
    z-index: 999;
    height: calc(100vh - 90px);
    transform: translateX(-100%);
    transition-duration: 250ms;
    overflow: auto;
    justify-content: space-between;

    #top-part-menu {
        //height: calc(100% - 50px);
        //min-height: 372px;
    }

    .menu-separator {
        border-top: 1px solid white;
        border-bottom: 0;
    }

    .synchrone-blue-link {
        background-color: $blueSynchrone;
    }

    &.main-navigation-open {
        transform: translateX(0);
    }

    a.current {
        color: $blueLightSynchrone;
    }

    a {
        padding-left: 0px;

        &:hover {
            background-color: #e7e000;
            color: $blueSynchrone !important;
        }
    }

    a {
        text-decoration: none;
        color: white;
        display: block;
        font-size: 17px;
        font-weight: normal;
        width: 100%;
        height: 50px;
        line-height: 50px;
        overflow: hidden;
        font-family: "Montserrat", sans-serif;

        i {
            font-size: 28px;
            margin-right: 25px;
            margin-left: 25px;
            vertical-align: middle;
        }
    }
}

.menu-button {
    float: left;
    position: relative;
    background-color: #25b9d6;
    width: 80px;
    height: 90px;
    margin: 0;
    cursor: pointer;

    .bar {
        position: relative;
        width: 40px;
        height: 3px;
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        transition-duration: 250ms;
    }

    .bar-1 {
        top: 30px;
    }

    .bar-2 {
        top: 44px;
    }

    .bar-3 {
        top: 58px;
    }

    &.menu-active {
        .bar-1 {
            top: 41px;
            transform: rotate(45deg);
        }

        .bar-2 {
            top: 38px;
            transform: rotate(-45deg);
        }

        .bar-3 {
            top: 35px;
            transform: rotate(-45deg);
        }
    }

    .back-url {
        font-size: 28px;
        display: block;
        margin-top: -25px;
    }
}
