@import "modules/variables.scss";
@import "modules/mixins.scss";

.absence-history-line {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    align-items: center;
    border-top: 1px solid #a51160;
    padding-top: 15px;

    .absence-history-line-event-code-description {
        font-weight: 700;

        .absence-history-line-days-count {
            color: slategray;
            font-weight: 500;
        }
    }

    .absence-history-line-details {
        width: 80%;

        .absence-history-line-action-at {
            color: slategray;
        }
    }

    .absence-total-container {
        width: 12%;
    }
}
