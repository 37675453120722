@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.insurance-item {
    background-color: #f0f9f8;
    font-size: 12px;
    width: 98%;
    margin: 0 auto;
    padding: 12px;
    line-height: 35px;

    .label-item {
        color: $documentsColor;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: normal;
        line-height: normal;
        text-transform: uppercase;
        display: inline-block;
        width: 210px;
    }

    .insurance-contact {
        font-weight: 400;

        .phone-item {
            display: inline-block;
            border-right: 1px solid $documentsColor;
            padding-right: 8px;

            i {
                transform: rotateY(180deg);
                width: 25px;
                font-size: 15px;
                margin-right: 4px;
            }
        }

        .email-content {
            color: $blueSynchrone;
            display: inline-block;
            padding: 0 8px;

            .email-item {
                color: $blueSynchrone;
            }

            i {
                font-size: 15px;
                width: 25px;
            }
        }
    }

    .url-item {
        display: inline-flex;
        line-height: 32px;
        height: 32px;
        align-items: center;

        .url-picto {
            background-color: $documentsColor;
            color: $white;
            border-radius: 6px;
            text-align: center;
            display: inline-block;
            width: 45px;
            height: 24px;
            line-height: 24px;

            i {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .url-content {
            display: inline-block;
            line-height: normal;
            margin-left: 5px;

            .url-content-label {
                display: block;
            }

            a {
                color: $blueSynchrone;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 150px;
            }
        }
    }

    .insurance-address {
        font-weight: 400;

        i {
            font-size: 20px;
            width: 25px;
        }
    }
}
