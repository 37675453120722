@import "modules/variables.scss";
@import "modules/mixins.scss";

.lb-overtime-delete {
    line-height: initial;
    text-align: left;

    .lb-overtime-delete-header {
        height: 70px;
        line-height: 70px;
        width: 100%;
        background-color: $duckColor;
        padding-left: 26px;
        color: $white;
        text-transform: uppercase;
        font-weight: 700;

        @include onMobile {
            text-align: center;
            padding-left: 0;
        }
    }

    > .lb-overtime-delete-label {
        padding-left: 26px;
        padding-top: 15px;
        letter-spacing: 0.12px;
        font-size: 15px;

        @include onMobile {
            padding: 15px;
        }

        > .lb-overtime-delete-description {
            color: $hoursNotWorked;
            display: inline-block;
            text-transform: uppercase;
            margin-left: 15px;
            font-weight: 800;

            @include onMobile {
                display: block;
                padding: 25px;
                margin-left: 0;
                text-align: center;
            }
        }
    }

    > .lb-overtime-delete-recap {
        padding-left: 26px;
        padding-top: 15px;

        @include onMobile {
            padding: 0 15px;
        }

        .cant-delete-overtime-warning {
            color: $corail;

            @include onMobile {
                margin: 15px 0;
            }
        }

        .overtime-days-container-header {
            height: 28px;
            margin-left: 200px;
            margin-top: 20px;
            font-size: 13px;
            font-style: italic;

            @include onMobile {
                display: none;
            }

            .col1 {
                display: inline-block;
                width: 100px;
                padding-left: 15px;
            }

            .col2 {
                display: inline-block;
                width: 100px;
                padding-left: 25px;
            }

            .col3 {
                display: inline-block;
            }

            .tickets-header {
                width: 180px;
            }
        }

        .overtime-delete-ticket-line {
            @include pie-clearfix();

            .overtime-delete-date {
                width: 200px;
                text-transform: capitalize;
                font-weight: 700;
                float: left;
            }

            .overtime-ticket-amount {
                float: left;
                width: 180px;
                text-align: center;
            }
        }

        .overtime-delete-constraint-line {
            @include pie-clearfix();

            .overtime-delete-date {
                width: 200px;
                text-transform: capitalize;
                font-weight: 700;
                float: left;
            }
        }

        .overtime-delete-day-container {
            padding: 15px 0;
            @include pie-clearfix();

            @include onMobile {
                display: flex;
                height: auto;
                min-height: 34px;
            }

            .overtime-delete-date {
                width: 200px;
                text-transform: capitalize;
                font-weight: 700;
                float: left;
            }

            .overtime-delete-hour-container {
                float: left;
                padding-left: 15px;

                .hour-separator {
                    width: 60px;
                    display: inline-block;
                    text-align: center;

                    @include onMobile {
                        width: 20px;
                    }
                }
            }
        }
    }

    > .lb-overtime-delete-actions {
        width: 503px;
        margin: 20px auto 20px auto;
        font-size: 13px;
        text-align: center;
        height: 80px;

        @include onMobile {
            width: auto;
            display: flex;
            justify-content: center;
            margin: 20px;
            gap: 20px;
        }

        > .lb-overtime-delete-confirm {
            color: $white;
            //display: inline-block;
            background-color: $notifierSuccessColor;
            width: 240px;
            height: 40px;
            line-height: 40px;
            border-radius: 50px;
            cursor: pointer;
        }

        > .lb-overtime-delete-cancel {
            margin-left: -11px;
            margin-right: 12px;
            display: inline-block;
            color: $white;
            width: 240px;
            height: 40px;
            line-height: 40px;
            background-color: $inactiveSendMonth;
            border-radius: 50px;
            cursor: pointer;

            @include onMobile {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
