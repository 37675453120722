@import "modules/variables.scss";
@import "modules/mixins.scss";

.activity-days-amount-block {
    padding: 11px 23px;
    text-align: right;
    border-right: 2px solid #f2f2f2;
    width: 5%;
    font-size: 13px;

    .activity-days-count {
        margin-bottom: 20px;
    }

    .no-prestation {
        color: $corail;
        font-weight: bold;
    }
}
