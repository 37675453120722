@import "modules/variables.scss";
@import "modules/mixins.scss";

.next-btn {
    @include event-type-button($notifierSuccessColor);
}

.cancel-btn {
    @include event-type-button($inactiveSendMonth);
}

.disabled-btn {
    @include event-type-button($inactiveSendMonth);
    // margin: 20px auto;
    // position: absolute;
    // bottom: 0;
     cursor: not-allowed !important;
    // left: calc((100% - 235px) / 2);

    > .label {
        display: block;
    }
}
