@import "modules/variables.scss";
@import "modules/mixins.scss";

.article {
    a {
        color: #337ab7;
    }

    h1 {
        font-size: 24px;
        font-weight: 600;
        color: $blueSynchrone;
    }

    h2 {
        font-size: 18px;
    }

    img {
        margin: 20px 0;
        max-width: 100%;
        height: auto !important;
    }

    img[align="left"],
    img[style*="float: left"],
    img[style*="float:left"] {
        margin: 0 20px 0 0 !important;
    }
    img[align="right"],
    img[style*="float: right"],
    img[style*="float:right"] {
        margin: 0 0 20px 20px;
    }

    // p {
    //     clear: both;
    // }
}

.published-at {
    font-style: italic;
}

.published-by {
    a {
        color: #337ab7 !important;
    }
}

.back-news {
    position: relative;
    display: block;
    width: 1280px;
    top: 115px;
    margin: 0 auto;
    color: $blueSynchrone;
    font-size: 28px;

    > .fa-newspaper {
        padding-right: 20px;
    }
}
