@import "modules/variables.scss";
@import "modules/mixins.scss";

.page-header {
    width: 100%;
    z-index: 10;
    padding: 20px 0;
    font-size: 32px;
    display: inline-block;
    //background-color: #fff;

    .breadcrumb {
        display: flex;

        > .title {
            height: 70px;
            align-items: flex-end;
            display: flex;
            font-size: 32px;
            margin-bottom: 30px;
            color: $blueSynchrone;
        }

        > .link-to-union {
            font-size: 24px;
            height: 70px;
            align-items: flex-end;
            display: flex;
            color: $blueSynchrone;
        }

        > .links-separator {
            margin: 0 13px;
            font-size: 24px;
            height: 70px;
            align-items: flex-end;
            display: flex;
            color: $blueSynchrone;
        }

        .active {
            font-size: 24px !important;
            color: $documentsColor !important;
            font-weight: 700;
        }

        .active-breadcrumb {
            font-size: 24px;
            height: 70px;
            align-items: flex-end;
            display: flex;
            color: $documentsColor !important;
            font-weight: 700;
        }
    }

    .page-header-btn {
        display: none;
    }

    &.page-header-parameters {
        background-color: transparent;
        color: $blueSynchrone;
    }

    @include onMobile {
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        padding: 0;
        height: 80px;
        justify-content: space-evenly;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        align-items: center;

        &.page-header-contact {
            background-color: $contactColor;
            color: $white;
        }

        &.page-header-news {
            background-color: $newsColor;
            color: $white;
        }

        &.page-header-timesheet {
            background-color: $timesheetColor;
            color: $white;
        }

        &.page-header-documents {
            background-color: $documentsColor;
            color: $white;
        }

        &.page-header-validation {
            background-color: $adminHeaderColor;
            color: $blueSynchrone;
        }

        &.page-header-hours-not-worked {
            background-color: $hoursNotWorked;
            color: $white;
        }

        &.page-header-absence {
            background-color: $vacationColor;
            color: $white;
        }

        &.page-header-parameters {
            background-color: $white;
            color: $blueSynchrone;
        }

        .page-header-btn {
            display: block;
            position: absolute;
            left: 15px;
            color: inherit;
            font-size: 28px;
        }

        .page-header-title {
            max-width: 80vw;
        }
    }
}
