@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.file-row {
    color: $blueSynchrone;
    display: block;
    font-size: 15px;
    font-weight: 600;
    background-color: white;
    position: relative;
    height: 60px;
    line-height: 60px;
    padding: 0 16px;

    // &:first-of-type {
    //     &:not(.nested) {
    //         border-top: 4px solid $green;
    //     }
    // }

    .file-row-label {
        display: inline-block;
        width: calc(94% - 10px);
        margin-left: 10px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
        white-space: nowrap;
    }

    .download-icon {
        display: inline-block;
        font-size: 24px;
        transform: rotate(180deg);
        color: $documentsColor;
    }

    &.union-row {
        width: 100%;
        margin-left: -5px;
    }
}
