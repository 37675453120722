@import "modules/variables.scss";
@import "modules/mixins.scss";

.warning-delete-specific-cra {
    color: $corail;
    padding-left: 85px;
    padding-bottom: 25px;
    text-align: left;
    font-weight: bold;
    font-size: 13px;

    @include onMobile {
        padding: 0;
        margin: 15px 0;
    }
}

.certified-container {
    padding-bottom: 15px;
}
