@import "modules/variables.scss";
@import "modules/mixins.scss";

.extra-activities-validation-comment {
  padding: 25px;

  .extra-activities-validation-comment-label {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 700;

    > i {
      font-size: 17px;
      margin-right: 5px;
    }
  }

  .extra-activities-validation-comment-input {
    background-color: #e9e8e9;
    min-height: 80px;
    width: 100%;
    border: none;
    resize: none;
    font-family: $mainFont;
  }
}

.extra-activities-content {
  padding: 15px 45px 0 25px;
  color: $pantone;

  .extra-activities-content-warning {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 15px;
    padding-bottom: 15px;
    line-height: 20px;
    letter-spacing: 0.125px;
  }

  .extra-activities-content-confirm {
    text-align: center;
  }

  .validate-activity-actions {
    margin-top: 20px;
    text-align: center;
    display: flex;
    justify-content: space-evenly;

    > .cancel-btn {
      background-color: $inactiveSendMonth;
    }

    > .refuse-btn {
      @include event-type-button($corail);
    }
  }
}

