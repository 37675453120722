@import "modules/variables.scss";
@import "modules/mixins.scss";

.timesheet-notifier {
    z-index: 12000;
    position: fixed;
    top: 102px;
    //display: none;
    //opacity: 0;
    //left: calc((100vw - 1200px) / 2);
    left: 50%;
    width: 1200px;
    height: 70px;
    line-height: 70px;
    color: $white;
    font-size: 44px;
    transform: translateX(-50%) translateY(-300%);
    transition: transform 1s, opacity 1s linear, visibility 0s linear 1s;
    visibility: hidden;

    @include onMobile {
        //display: flex;
        align-items: center;
    }

    @include onMobile {
        display: flex;
        top: auto;
        bottom: 15px;
        width: calc(100vw - 30px);
        line-height: 1.2;
        transform: translateX(-50%) translateY(100px);
        border-radius: 8px;
    }

    &.error-notifier {
        background-color: $notifierErrorColor;
    }

    &.success-notifier {
        background-color: $notifierSuccessColor;
    }

    .pre-icon,
    .post-icon {
        display: inline-block;
        width: 100px;
        text-align: center;

        @include onMobile {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;

            i {
                font-size: 30px;
            }
        }
    }

    .notifier-text {
        font-weight: 700;
        font-family: "Open Sans";
        font-size: 15px;
        letter-spacing: 0.13px;
        width: 77%;
        vertical-align: bottom;
        display: inline-block;
        margin-left: 75px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include onMobile {
            margin-left: 0;
            text-overflow: unset;
            white-space: unset;
            font-size: 12px;
        }
    }

    &.open {
        display: block;
        //opacity: 1;
        visibility: visible;
        transform: translateX(-50%) translateY(0);
        transition: transform 1s, opacity 1s linear, visibility 0s linear;

        @include onMobile {
            display: flex;
            align-items: center;
        }
    }
}
