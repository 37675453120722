@import "modules/variables.scss";
@import "modules/mixins.scss";

.actions-block {
    padding: 11px;
    border-right: 2px solid #f2f2f2;
    width: 22%;

    .actions-group {
        line-height: 24px;
        margin-bottom: 18px;

        .actions-group-content {
            // .action-buttons-line-of-4 {
            //     > .action-btn {
            //         width: 25%;
            //     }
            // }

            // .action-buttons-line-of-5 {
            //     > .action-btn {
            //         width: 20%;
            //     }
            // }

            .validation-btn {
                width: 50px !important;
            }

            .action-btn {
                text-align: center;
                display: inline-block;
                width: 25px;

                .comment-count-container {
                    cursor: pointer;
                    font-size: 12px;

                    .comment-count {
                        color: white;
                        font-family: "Open Sans";
                    }
                }

                > i {
                    font-size: 20px;
                }

                > i:not(.green-btn):not(.coral-btn) {
                    cursor: pointer;
                }

                &.action-comment-container {
                    width: 30px;
                }
            }

            .green-btn {
                font-size: 20px;
                color: $notifierSuccessColor;
                width: 25px;
                text-align: center;
            }

            .coral-btn {
                font-size: 20px;
                text-align: center;
                color: $corail;
                width: 25px;
            }
        }
    }
}
