@import "modules/variables.scss";
@import "modules/mixins.scss";

.step3-contents {
    text-align: left;

    @include onMobile {
        width: 100%;
    }

    form {
        margin-bottom: 35px;
    }

    .input-type-radio-group {
        font-size: 14px;
        display: flex;

        .input-type-radio-label {
            text-wrap: nowrap;
        }

        .input-type-radios {
            @include onMobile {
                flex-direction: column;
            }
        }

        .input-type-radio {
            display: inline-block;
            margin-left: 12px;

            > label {
                margin-left: 25px;
                font-size: 12px;
                color: $blueSynchrone;
                font-weight: 600;
            }

            > .radio-button {
                opacity: 0;
            }

            > .fake-radio::before {
                content: "";
                display: inline-block;

                height: 13px;
                width: 13px;

                border: 1px solid;
                border-radius: 50%;
            }

            > .fake-radio::after {
                content: "";
                display: inline-block;
                height: 9px;
                width: 9px;
                border-radius: 50%;
                background-color: $menuColor;
            }

            > .fake-radio {
                position: relative;
                top: -20px;
            }

            > .fake-radio::before,
            > .fake-radio::after {
                position: absolute;
            }

            > .fake-radio::before {
                top: 3px;
            }

            > .fake-radio::after {
                left: 3px;
                top: 6px;
            }

            > .radio-button + .fake-radio::after {
                content: none;
            }

            > .radio-button:checked + .fake-radio::after {
                content: "";
            }
        }
    }

    .overtime-days-container {
        margin-top: 25px;

        > .overtime-days-container-left {
            width: calc(100% - 300px);
            float: left;

            @include onMobile {
                width: 100%;
                float: none;
                margin-bottom: 25px;
            }

            > .overtime-days-container-header {
                height: 28px;
                margin-left: 210px;
                font-size: 13px;
                font-style: italic;

                @include onMobile {
                    display: none;
                }

                .col1 {
                    display: inline-block;
                    width: 200px;
                }

                .col2 {
                    display: inline-block;
                    width: 180px;
                }

                .col3 {
                    display: inline-block;
                }
            }

            > .overtime-day-line {
                clear: both;
                height: 29px;

                @include onMobile {
                    display: flex;
                    height: auto;
                    min-height: 34px;
                }

                .total-hours-container {
                    display: inline-block;
                    margin-left: 70px;

                    @include onMobile {
                        margin-left: 0;
                        display: none;
                    }
                }

                .hour-line-container {
                    float: left;
                    border-right: 2px solid $hoursNotWorked;
                    margin: 2px 0;

                    @include onMobile {
                        float: none;
                        display: flex;
                        border-right: none;
                        flex-direction: column;
                    }

                    .hour-line {
                        height: 29px;

                        @include onMobile {
                            display: flex;
                        }

                        &:hover {
                            background-color: #f4f4f4;

                            input[type="time"] {
                                background-color: #f4f4f4;
                            }

                            > .hour-input-actions {
                                opacity: 1;
                            }
                        }
                    }

                    .hour-input-actions {
                        display: inline-flex;
                        justify-content: space-evenly;
                        align-items: center;
                        width: 75px;
                        text-align: center;
                        opacity: 0;

                        @include onMobile {
                            opacity: 1;
                            width: 60px;
                            justify-content: flex-start;
                        }

                        i {
                            position: relative;
                            top: 3px;
                            display: inline-block;
                            cursor: pointer;

                            @include onMobile {
                                top: 0;
                                margin: 0 0 0 15px;
                            }
                        }
                    }
                }

                > .label {
                    text-transform: capitalize;
                    font-weight: 700;
                    width: 210px;
                    float: left;

                    @include onMobile {
                        width: 100vw;
                        float: none;
                        display: flex;
                        align-items: center;
                    }
                }

                > .ticket-input-container {
                    display: inline-block;
                    border-left: 2px solid $hoursNotWorked;
                    padding-left: 10px;

                    /* Chrome, Safari, Edge, Opera */
                    > .ticket-input::-webkit-outer-spin-button,
                    > .ticket-input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    /* Firefox */
                    > .ticket-input[type="number"] {
                        -moz-appearance: textfield;
                    }
                }

                .ticket-input,
                input[type="time"] {
                    border: none;
                    border-bottom: 1px solid #979797;
                    font-family: $mainFont;
                    font-size: 15px;
                    color: #4a4a4a;
                    width: 80px;
                    text-align: center;
                }

                input[type="time"]::-webkit-inner-spin-button,
                input[type="time"]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    margin: 0;
                }

                input[type="time"]::-webkit-clear-button {
                    -webkit-appearance: none;
                }
            }
        }

        > .overtime-days-container-right {
            float: left;

            @include onMobile {
                width: 100%;
                float: none;
            }

            > .constraint-total {
                margin-bottom: 15px;

                > .label {
                    width: 140px;
                    font-size: 15px;
                    display: inline-block;
                }

                > .input-container {
                    display: inline-block;
                    background-color: $hoursNotWorked;
                    height: 50px;
                    padding: 12px 38px;

                    > .total-constraint-input {
                        background-color: $hoursNotWorked;
                        border: none;
                        border-bottom: 1px solid $blueSynchrone;
                        text-align: center;
                        height: 26px;
                        width: 80px;
                        font-size: 15px;
                        color: $blueSynchrone;
                        font-weight: 700;
                        font-family: $mainFont;
                    }

                    /* Chrome, Safari, Edge, Opera */
                    > .total-constraint-input::-webkit-outer-spin-button,
                    > .total-constraint-input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    /* Firefox */
                    > .total-constraint-input[type="number"] {
                        -moz-appearance: textfield;
                    }
                }
            }

            > .hours-total {
                > .label {
                    width: 140px;
                    font-size: 15px;
                    display: inline-block;
                }

                > .input-container {
                    display: inline-block;
                    background-color: $hoursNotWorked;
                    height: 50px;
                    padding: 12px 38px;

                    > .input {
                        background-color: $hoursNotWorked;
                        border: none;
                        text-align: center;
                        height: 26px;
                        width: 80px;
                        font-size: 15px;
                        color: $blueSynchrone;
                        font-weight: 700;
                        font-family: $mainFont;
                    }
                }
            }

            > .ticket-total {
                > .label {
                    width: 140px;
                    font-size: 15px;
                    display: inline-block;
                }

                > .input-container {
                    display: inline-block;
                    background-color: $hoursNotWorked;
                    height: 50px;
                    padding: 12px 38px;

                    > .input {
                        background-color: $hoursNotWorked;
                        border: none;
                        text-align: center;
                        height: 26px;
                        width: 80px;
                        font-size: 15px;
                        color: $blueSynchrone;
                        font-weight: 700;
                        font-family: $mainFont;
                    }
                }
            }
        }
    }

    .step3-comment {
        clear: both;

        > .step3-comment-label {
            font-size: 15px;
            height: 20px;
            line-height: 20px;
            font-weight: 700;
            color: $blueSynchrone;
            text-align: left;
            margin-bottom: 11px;
        }

        > .step3-comment-input {
            background-color: #f4f4f4;
            color: $blueSynchrone;
            outline: none;
            border: none;
            font-family: "Open Sans";
            font-weight: 00;
            width: 100%;
            min-height: 80px;
            font-size: 12px;
            resize: none;
        }
    }

    .btn-container {
        margin-top: 80px;
        text-align: center;


        .next-btn {
            font-size: initial;
            margin-bottom: 20px !important;
        }
    }
}

.remote-working-container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: normal;
    text-align: left;
    //padding-left: 40px;
    margin-bottom: 25px;

    .remote-working-icon {
        margin-right: 5px;
    }

    > .checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked + .checkmark:after {
            display: block;
        }
    }

    > .checkmark {
        height: 20px;
        width: 20px;
        display: inline-block;
        position: relative;
        left: 10px;
        top: 5px;
        background-color: #eee;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 5px;
            width: 6px;
            height: 12px;
            border: solid $menuColor;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

