@import "modules/variables.scss";
@import "modules/mixins.scss";

:root {
    --planning-day-size: 36px;
    --planning-day-gap: 8px;

    @include onMobile {
        --planning-day-size: 7vw;
        //--planning-day-size: 7vw;
        --planning-day-gap: 2vw;
        //--planning-day-gap: 1.5vw;
    }
}

.planning-hd {
    width: var(--planning-day-size);
    height: var(--planning-day-size);
    flex-shrink: 0;
    max-width: 40px;
    max-height: 40px;
    border-style: solid;
    border-width: 0;
    border-radius: 50%;
    //border-radius: 8px;
    border-color: transparent;
    background-image: -webkit-linear-gradient(-45deg, var(--am-color, white) 50%, var(--pm-color, white) 50%);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

    &.planning-hd-am-sickness,
    &.planning-hd-pm-sickness {
        background-color: #{$vacationColor};
        background-image: url(/img/sickness.png);
        background-position: right center;
        //border: none;
    }

    &.planning-hd-am-weekend {
        --am-color: #87878788;
    }
    &.planning-hd-pm-weekend {
        --pm-color: #87878788;
    }

    &.planning-hd-am-validated {
        --am-color: #{$vacationColor};
        --remote-am-color: transparent !important;
    }
    &.planning-hd-pm-validated {
        --pm-color: #{$vacationColor};
        --remote-pm-color: transparent !important;
    }


    &.planning-hd-am-submitted {
        --am-color: #{$vacationColor}88;
        --remote-am-color: transparent !important;
    }
    &.planning-hd-pm-submitted {
        --pm-color: #{$vacationColor}88;
        --remote-pm-color: transparent !important;
    }

    .planning-remote-hd {
        width: var(--planning-day-size);
        height: var(--planning-day-size);
        max-width: 40px;
        max-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        background-image: linear-gradient(-45deg, var(--remote-pm-color, rgba($activityColor, 0.35)) 50%, var(--remote-am-color, rgba($activityColor, 0.35)) 50%);
        color: $activityColor;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &.planning-remote-hd-am {
            --remote-am-color: #{$activityColor};
        }

        &.planning-remote-hd-pm {
            --remote-pm-color: #{$activityColor};
        }
    }
}


.planning-days-container {
    display: flex;
    padding-inline: var(--planning-day-gap);
    gap: var(--planning-day-gap);

    @include onMobile {
        width: 62vw;
        gap: unset;
        justify-content: space-evenly;

        &.without-weekends {
            --planning-day-size: 9.5vw;

            i.fa {
                font-size: 20px;
            }

            :nth-child(6),
            :nth-child(7) {
                display: none;
            }
        }
    }

}
