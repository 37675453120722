@import "modules/variables.scss";
@import "modules/mixins.scss";

.person-type-badge {
    display: inline-block;
    width: 110px;
    color: $blueSynchrone;
    background-color: #e9e8e9;
    border-radius: 9px;
    font-size: 11px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.08px;
    line-height: 7.4px;
    margin-right: 5px;
    padding: 2px 0;
    height: 18px;

    &.one-liner {
        line-height: 14.8px;
    }
    &.two-liner {
        line-height: 7.4px;
    }
}
