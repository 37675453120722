@import "modules/variables.scss";
@import "modules/mixins.scss";

#contact-menu {
    .contact-menu-title {
        padding: 20px 0;
        font-size: 32px;
        display: inline-block;
        width: calc(100% - 615px);
    }

    .contact-menu-navigation {
        display: inline-block;
        text-align: right;
        float: right;
        padding: 15px 0;

        .contact-menu-item {
            font-size: 18px;
            border: 3px solid $vacationColor;
            border-radius: 100px;
            width: 300px;
            display: inline-block;
            text-align: center;
            color: $blueSynchrone;
            height: 49px;
            line-height: 43px;
            font-weight: 700;

            &.active {
                background-color: $vacationColor;
                color: white;
            }

            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }

        @include onMobile{
            margin: 15px;
            display: flex;
            float: none;
            padding: 0;

            .contact-menu-item {
                display: flex;
                font-size: 14px;
                line-height: 1.2;
                align-items: center;
                border: none;
                height: auto;
                border-radius: 0;
                border-bottom: 3px solid transparent;
                padding-bottom: 5px;
                text-align: center;
                justify-content: center;

                &.active {
                    color: $blueSynchrone;
                    background-color: transparent;
                    border-bottom: 3px solid $vacationColor;
                }

                &:not(:last-of-type) {
                    margin-right: 0;
                }
            }
        }
    }
}
