@import "modules/variables.scss";
@import "modules/mixins.scss";

.mobile-overlay-container {
    text-align: center;
    position: sticky;
    z-index: 10000;
    background-color: white;
    width: 100%;
    height: 100%;
    top: 0;
    overflow: scroll;

    .mobile-overlay-content {
        transform: translateY(-50%);
        position: relative;
        top: 50%;

        .mobile-overlay-notifier-success {
            background-color: $notifierSuccessColor;
            width: 100%;
            margin: 0 auto;
            color: white;
            z-index: 99999;
            position: fixed;
            top: 102px;
            height: 70px;
            line-height: 70px;
            color: $white;
            transform: translateY(-300%);
            transition: all 1s;
        }

        .mobile-overlay-notifier-error {
            background-color: $corail;
            width: 100%;
            margin: 0 auto;
            color: white;
            z-index: 99999;
            position: fixed;
            height: 70px;
            line-height: 70px;
            color: $white;
            transform: translateY(-300%);
            transition: all 1s;
        }

        .synchrone-overlay-logo {
            background: transparent url("/logo-my-synchrone.svg") center center
                no-repeat;
            height: 70px;
        }

        .navigate-anyway-btn {
            color: white;
            background-color: #17313a;
            padding: 10px 5px;
            font-size: 12px;
        }
    }

    .ios-information {
        font-size: 12px;
        font-style: italic;
        position: absolute;
    }

    @media screen and (orientation: portrait) {
        .navigate-anyway-btn {
            margin: 55px auto 35px auto;
            width: 50vw;
        }

        .mobile-overlay-notifier-error {
            top: 102px;
        }

        .mobile-overlay-notifier-success {
            top: 102px;
        }

        .ios-information {
            bottom: 30px;
        }

        .mobile-warning {
            margin: 30px auto;
        }
    }

    @media screen and (orientation: landscape) {
        .navigate-anyway-btn {
            margin: 0 auto;
            width: 25vw;
        }

        .mobile-overlay-notifier-error {
            top: 200px;
        }

        .mobile-overlay-notifier-success {
            top: 200px;
        }

        .ios-information {
            bottom: 0;
        }

        .mobile-warning {
            margin: 10px auto;
        }
    }
}
