@import "modules/variables.scss";
@import "modules/mixins.scss";

.section-container {
    width: 100%;
    margin-bottom: 50px;
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;

    @include onMobile {
        width: 100%;
    }

    .section-title {
        background-color: $documentsColor;
        color: white;
        font-size: 24px;
        font-weight: 700;
        height: 62px;
        line-height: 62px;
        padding-left: 24px;
        border-radius: 8px 8px 0 0;
        position: relative;

        @include onMobile {
            padding-left: 15px;
            font-size: 4vw;
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: normal;
        }

        .section-title-label {
            display: inline-block;
            width: calc(100% - 65px);

            @include onMobile {
                display: block;
                position: relative;
            }
        }

        .section-title-image {
            display: inline-block;
            position: absolute;

            @include onMobile {
                display: block;
                position: relative;
            }
        }
    }
}
