@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.ssct-item {
    background-color: #f0f9f8;
    font-size: 12px;
    width: 98%;
    margin: 0 auto;
    padding: 12px;
    line-height: 20px;
}

.ssct-title {
    color: $documentsColor;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
    display: inline-block;
    margin-bottom: 15px;
}

.ssct-contact {
    display: flex;

    .ssct-persons {
        width: 265px;
        font-weight: 600;
    }
}

.separator {
    border-right: 1px solid $documentsColor;

    .triangle {
        background-color: #f0f9f8;
        border: 1px solid $documentsColor;
        border-width: 1px 1px 0 0;
        transform: rotate(45deg);
        position: relative;
        left: 4px;
        top: calc((100% / 2) - 2px);
        width: 5px;
        height: 5px;
    }
}

.ssct-emails {
    display: flex;
    align-items: center;
    padding-left: 8px;

    i {
        font-size: 15px;
        width: 25px;
        display: inline-block;
    }

    .ssct-emails-content {
        display: inline-block;

        a {
            display: block;
            color: $blueSynchrone;
        }
    }
}
