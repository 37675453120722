@import "modules/mixins.scss";

.contact {
    cursor: pointer;
    width: calc((100% - 40px) / 3);
    //width: 30%;
    //height: 8%;
    height: 100px;
    line-height: 100px;
    background: #fff;
    padding: 0 10px;
    //margin-bottom: 20px;
    //margin-right: 75px;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:nth-child(3n) {
        margin-right: 0;
    }

    img {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        vertical-align: middle;
        margin-right: 10px;
    }

    @include onMobile {
        height: auto;
        width: 100%;
        box-shadow: 3px 3px 10px #e0e0e0;
        line-height: 80px;
        font-size: 16px;

        img {
            width: 60px;
            height: 60px;
            border-radius: 30px;
        }
    }


    // float: left;
    // margin: 20px (150px / 6);

    &.consultant-contact {
        line-height: 1.6;
        padding: 10px 15px;
        position: relative;

        .contact-birthdate {
            position: absolute;
            right: 10px;
            bottom: 10px;
            font-size: 12px;
            font-weight: 700;
            font-style: italic;

            i {
                font-size: 16px;
                font-weight: 100;
            }
        }
    }
}
