@import "modules/variables.scss";
@import "modules/mixins.scss";

.absence-block {
    // display: inline-block;
    border-right: 2px solid #f2f2f2;
    width: 26%;
    padding: 11px 18px;

    .absence-block-group {
        font-size: 13px;
        margin-bottom: 18px;
        line-height: 24px;

        .absence-group-title {
            font-weight: 600;
        }

        .absence-block-content {
            border-left: 6px solid $vacationColor;
            padding-left: 7px;
        }
    }

    .absence-validation-event-container {
        position: relative;

        .backwarded-icon {
            position: absolute;
            left: -30px;
            top: 7px;
            color: $corail;
        }
    }
}
