@import "modules/variables.scss";

.timesheet-container {
    background-color: $timesheetColor !important;
    min-height: 100vh;
    padding-bottom: 80px;

    .cra-sub-area {
        margin-top: 15px;
        position: relative;
        min-height: 37px;
        display: flex;
        justify-content: center;
    }

    .month-header.submitted {
        background-color: $timesheetColor;
    }

    .send-month-btn {
        position: absolute;
        margin: 0 auto 25px auto;
        //display: block;
        opacity: 1;
        transition-duration: 300ms;
        transform: translateY(0);
        visibility: visible;
        --loader-color: #{$timesheetColor};

        &.active {
            background-color: #fff !important;
            color: $timesheetColor;
        }

        &.send-month-btn-hidden {
            opacity: 0;
            visibility: hidden;
            transform: translateY(10px);
        }
    }

    .cra-help-text {
        position: absolute;
        opacity: 1;
        transition-duration: 300ms;
        transform: translateY(0);
        visibility: visible;
        height: 37px;
        line-height: 37px;
        color: #fff;
        font-weight: bold;

        &.cra-help-text-hidden {
            opacity: 0;
            visibility: hidden;
            transform: translateY(10px);
        }
    }

    .day-details {
        background-color: $activityHovered;
        padding: 15px;

        .day-details-header {
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: bold;
        }

        .day-remote {
            font-size: 20px;

            &.day-remote-full {
                color: $activityColor;
            }

            &.day-remote-am {
                background-image: linear-gradient(90deg, $activityColor 50%, rgba($activityColor, 0.35) 50%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &.day-remote-pm {
                background-image: linear-gradient(90deg, rgba($activityColor, 0.35) 50%, $activityColor 50%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .day-details-block {
            margin-block: 10px;
            padding-inline: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;

            > div {
                width: 100%;
            }

            .day-details-desc {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100vw - 80px);
            }
        }

        .hd-type-absence,
        .hd-type-sickness {
            border-left: 9px solid $vacationColor;
        }

        .hd-type-activity {
            border-left: 9px solid $activityColor;
        }

        .hd-type-autofilled {
            border-left: 9px solid #c7c6c6;
        }

        .day-constraint {
            border-left: 9px solid $hoursNotWorked;
        }

        .day-extra-constraint {
            border-left: 9px solid $newIntervention;
        }

        .day-extra {
            border-left: 9px solid $newHoursNotWorked;
        }
    }

    .mobile-counters-btn {
        color: #fff;
        font-weight: 500;
        text-align: right;
        margin-right: 15px;
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        i {
            margin-left: 10px;
            color: $vacationColor;
            text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
            1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
        }
    }
}
