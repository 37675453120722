@import "modules/variables.scss";
@import "modules/mixins.scss";

.lb-w1200 {
    .lb-counters-mobile {
        line-height: initial;
        height: initial;
        text-align: left;

        .lb-counters-mobile-header {
            display: flex;
            height: 70px;
            justify-content: center;
            align-items: center;
            width: 100%;
            background-color: $vacationColor;
            color: $white;
            padding: 0 26px;
            font-weight: bold;
            .counters-mobile-header-title {
                font-size: 5vw;
            }

            .lb-counters-mobile-header-icon {
                font-size: 6vw;
            }
        }

        .lb-counters-mobile-content {
            margin: 20px;
            font-size: 4vw;

            .lb-counters-mobile-content-counters {
                padding-bottom: 10px;
                border-bottom: 3px solid $vacationColor;

                .counters-mobile-counters-header {
                    font-size: 4vw;
                    font-weight: 600;
                }

                .counters-mobile-counter {
                    padding: 10px 0;

                    .counters-mobile-counter-total {
                        .counters-mobile-counter-description {
                            font-weight: 500;
                            font-size: 4vw;
                            width: 28vw;
                            display: inline-block;
                        }

                        .counters-mobile-counter-total-count {
                            background-color: $vacationColor;
                            color: white;
                            text-align: right;
                            font-weight: 700;
                            line-height: 4vh;
                            display: inline-block;
                            padding: 0 5px;
                            width: 13vw;
                        }
                    }

                    .counters-mobile-counter-line {
                        margin-left: 28vw;

                        .counters-mobile-counter-line-total-count {
                            background-color: $vacationColorTransparent;
                            color: white;
                            text-align: right;
                            font-weight: 400;
                            line-height: 4vh;
                            display: inline-block;
                            padding: 0 5px;
                            width: 13vw;
                        }

                        .counters-mobile-counter-line-description {
                            display: inline-block;
                            margin-left: 2vw;
                            font-weight: 300;
                        }
                    }
                }
            }

            .lb-counters-mobile-content-history {
                padding-top: 15px;

                .counters-mobile-history-header {
                    font-size: 4vw;
                    font-weight: 600;
                    padding-bottom: 15px;
                }

                .counters-mobile-history-caption {
                    display: flex;
                    justify-content: space-between;

                    .counters-mobile-history-caption-item {
                        font-size: 4vw;
                        i {
                            padding-left: 5px;
                            color: $vacationColor;
                        }
                    }
                }
            }
        }

    }
}
