@import "modules/variables.scss";
@import "modules/mixins.scss";

.not-found-container {
    background-color: #fff;

    > .container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        width: 100%;

        > .error-code {
            color: $menuColor;
            display: inline-block;
            font-size: 40px;
            font-weight: 900;
            margin-right: 10px;
        }

        > .error-label {
            display: inline-block;
            color: $blueSynchrone;
            font-weight: 500;
            font-size: 40px;
            margin-bottom: 45px;
        }

        > .back-btn {
            display: block;
            cursor: pointer;
            text-decoration: none;
            color: $blueSynchrone;
            border: 1px solid $blueSynchrone;
            border-radius: 25px;
            width: 250px;
            margin: 0 auto;
            padding: 10px 0;
            font-weight: 600;
            font-size: 14px;
        }
    }
}
