@import "modules/variables.scss";
@import "modules/mixins.scss";

.person-row {
  .extra-activities-request-at-block {
    width: 23%;
    border-right: 2px solid #f2f2f2;
    padding: 12px;
  }

  .extra-activities-months-block {
    width: 25%;
    border-right: 2px solid #f2f2f2;
    padding: 12px;
  }

  .extra-activities-actions-block {
    width: 25%;
    padding: 12px;

    .action-btn {
      text-align: center;
      display: inline-block;
      width: 25px;
      font-size: 20px;
    }

    .action-btn:not(.green-btn):not(.coral-btn) {
      cursor: pointer;
    }

    .green-btn {
      font-size: 20px;
      color: $notifierSuccessColor;
      width: 25px;
      text-align: center;
    }

    .coral-btn {
      font-size: 20px;
      text-align: center;
      color: $corail;
      width: 25px;
    }
  }
}