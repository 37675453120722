@import "modules/variables.scss";
@import "modules/mixins.scss";

.lb-event-delete {
  .lb-event-delete-header {
    height: 70px;
    line-height: 70px;
    width: 100%;
    background-color: $duckColor;
    padding-left: 26px;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
  }

    > .lb-event-delete-message {
        font-size: 15px;
        font-family: $mainFont;
        color: $blueSynchrone;
        line-height: 100px;

        @include onMobile {
            line-height: 1.2;
            padding: 50px 0;
        }

        .lb-event-delete-nota-bene {
            font-style: italic;
            line-height: 20px;
            font-size: 13px;
        }

        > .event-code {
            text-transform: uppercase;
            font-weight: 700;

            @include onMobile {
                display: block;
                padding: 25px;
            }
        }

        > .event-code-absence {
            color: $vacationColor;
        }

        > .event-code-activity {
            color: $menuColor;
        }

        > .event-code-hours-not-worked {
            color: $hoursNotWorked;
        }
    }

    > .lb-event-delete-actions {
        display: inline-flex;
        width: 45%;
        justify-content: space-between;
        font-size: 13px;

        @include onMobile {
            width: auto;
            display: flex;
            justify-content: center;
            margin: 20px;
            grid-gap: 20px;
            gap: 20px;

            .cancel-btn {
                width: 240px;
            }

            .next-btn {
                width: 240px;
            }
        }
    }
}
