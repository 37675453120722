@import "modules/variables.scss";
@import "modules/mixins.scss";

.person-row-mobile-link {
    color: black
}

.person-row-mobile {
    background-color: #fff;
    border-top: 1px solid slategray;
    padding: 15px;
    display: flex;
    align-items: center;

    &:last-of-type {
        border-bottom: 1px solid slategray;
    }

    .person-row-mobile-name {
        font-weight: 700;
        font-size: 18px;
        padding-bottom: 5px;
    }

    .person-row-mobile-matricule {
        font-size: 14px;
        padding-top: 5px;
    }

    .person-row-mobile-details {
        width: 95%;
    }
}

.cra-month-header-mobile {
    font-size: 26px;
    text-align: center;
    padding: 10px 0;
}

.empty-validation-page {
    height: calc(100vh - 136px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    margin-top: -36px;
}