@import "modules/variables.scss";
@import "modules/mixins.scss";

.team-planning-page {
    text-align: center;
    background-color: #f2f2f2;
}

.planning-lines-containers {
    @include onMobile {
        margin-top: 70px;
        margin-inline: 2.5vw;
    }
}


.planning-week-selector-container {
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
    gap: 10px;
    width: 100vw;
    position: fixed;
    top: 80px;
    height: 80px;
    padding-top: 20px;
    background-color: #f2f2f2;
    z-index: 10;

    .planning-week-selector {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        width: 200px;
        height: 36px;
        border-radius: 18px;
        font-size: 13px;
    }

    .planning-week-selector-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        width: 36px;
        height: 36px;
        border-radius: 18px;
        color: #000;
    }
}

.team-planning-container {
    margin: 90px auto 0 auto;
    display: inline-flex;;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
    text-align: left;

    @media screen and (width < 1850px) {
        align-items: flex-start;
        padding: 0 15px;
    }

    @include onMobile {
        padding: 0;
        margin: 0;
        min-height: 100vh;
        width: 100vw;
    }

    .cra-list-title-container {
        padding-bottom: 20px;
        display: flex;

        .cra-list-title {
            font-size: 32px;
            height: 70px;
            align-items: flex-end;
            display: flex;
        }

        .caption-container {
            display: flex;
            margin-left: 60px;
            height: 70px;
            align-items: flex-end;

            .caption {
                margin-right: 20px;
                display: inline-block;

                .caption-color {
                    vertical-align: top;
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    margin-right: 5px;
                }

                .caption-title {
                    display: inline-block;
                }
            }
        }
    }

    .thead {
        display: flex;
    }

    .empty-header {
        width: 450px;
        margin-left: 10px;
        min-height: 10px;
        background-color: #f2f2f2;

        &.without-counters {
            width: 300px;
        }

        @include onMobile {
            width: 30vw !important;
        }

    }

    .day-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--planning-day-size);
        height: 40px;
        max-width: 40px;
        text-align: center;
        text-transform: capitalize;
        color: #9b9b9b;
        font-weight: normal;
        background-color: #f2f2f2;
        border-right: 1px solid #9b9b9b;
        padding: 0;

        &:last-of-type {
            border-right: unset;
        }

        @include onMobile {
            font-size: 11px;
        }
    }

    .absent-cell {
        background-color: $vacationColor;
    }

    .team-planning-footer {
        color: $greyDark;
        font-style: italic;
    }

    //&:hover {
    //    .managee-line:not(:hover) {
    //        opacity: 0.3;
    //    }
    //}

    .managee-line {
        position: relative;
        color: #000;
        display: flex;
        align-items: center;
        background-color: #fff;
        margin: 10px 0;
        padding: 3px 10px;
        min-height: 50px;
        border-radius: 8px;
        transition-duration: 250ms;

        @include onMobile {
            margin-right: 0;
            padding-right: 0;
            margin-left: 0 !important;
        }

        &.level-1 {
            margin-left: 30px;

            .managee-container {
                width: calc(100% - 30px);
            }
            @include onMobile {
                background-color: rgba(#fff, 0.4);
            }
        }

        &.level-2 {
            margin-left: 60px;

            .managee-container {
                width: calc(100% - 60px);
            }
            @include onMobile {
                background-color: rgba(#fff, 0.2);
            }
        }

        &.level-3 {
            margin-left: 90px;

            .managee-container {
                width: calc(100% - 90px);
            }
        }
    }

    .managee-container {
        position: relative;
        width: 450px;

        .open-manager-indicator {
            display: inline-block;
            cursor: pointer;
            position: absolute;
            right: 15px;
            // top: 50%;
            // transform: translateY(-50%);
            vertical-align: middle;
            font-weight: 900;
        }

        @include onMobile {
            width: 30vw !important;
        }
    }

    .managee-identity-container {
        display: inline-flex;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        line-height: 30px;
        white-space: nowrap;
        text-align: left;

        > .person-type-badge {
            vertical-align: middle;
            white-space: normal;

            @include onMobile {
                font-size: 10px;
            }
        }

        .managee-identity-label {
            display: inline-block;
            vertical-align: middle;
            max-width: calc(100% - 65px);
            text-overflow: ellipsis;
            overflow: hidden;

            @include onMobile {
                font-size: 11px;
            }
        }
    }

    .without-counters {
        @for $i from 0 through 3 {
            &.level-#{$i} {
                .managee-container {
                    width: 300px - ($i * 30px);
                  }
            }
        }

        .managee-identity-container {
            flex-direction: column;
            align-items: flex-start;
        }

        .managee-identity-label {
            max-width: 100%;
            order: 1;
        }

        .person-type-badge {
            order: 2;
            margin-bottom: 5px;
        }
    }

    .counters-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        padding: 10px;
        border-radius: 5px;
        user-select: none;

        @include onMobile {
            display: none;
        }

        i {
            margin-right: 5px;
        }
    }

    .weekend-btn {
        cursor: pointer;
        display: none;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 5px;
        user-select: none;
        -webkit-tap-highlight-color: transparent;

        @include onMobile {
            display: flex;
        }

        i {
            margin-right: 5px;
        }
    }
}
