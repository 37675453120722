@import "modules/variables.scss";
@import "modules/mixins.scss";

.lb-w1200 {
    .lb-download-cra {
        line-height: initial;
        height: initial;

        .activity-row {
            text-align: left;
            margin: 15px 0 15px 30px;

            input[type="checkbox"] {
                margin-right: 10px;
            }

            > label {
                color: $blueSynchrone;
                cursor: pointer;
            }
        }

        > .lb-download-cra-header {
            height: 70px;
            line-height: 70px;
            // text-align: left;
            width: 100%;
            background-color: $duckColor;
            color: $white;
            text-transform: uppercase;
            padding-left: 26px;
            position: relative;
            text-align: center;

            .download-cra-header-title {
                position: absolute;
                font-weight: 700;
            }

            .download-cra-month-header {
                position: absolute;
                display: inline-block;
                left: calc(50% - 50px);

                .month {
                    font-weight: 700;
                }

                .year {
                    font-weight: 400;
                }
            }

            .close-lb {
                position: absolute;
                right: 15px;
                top: 15px;
                font-size: 42px;
                cursor: pointer;
            }
        }

        .next-btn {
            border: none;
            margin-bottom: 30px;
        }

        input[type="checkbox"] {
            position: absolute;
            cursor: pointer;
            opacity: 0;
            height: 0;
            width: 0;

            &:checked + .checkmark:after {
                display: block;
            }
        }

        .checkmark {
            height: 20px;
            width: 20px;
            display: inline-block;
            position: relative;
            left: 10px;
            top: 5px;
            background-color: #eee;
            margin-right: 18px;
            cursor: pointer;

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 5px;
                width: 6px;
                height: 12px;
                border: solid $menuColor;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
}

#hidden-download-button {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: -100%;
    top: -100%;
}
