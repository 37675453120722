@import "modules/variables.scss";
@import "modules/mixins.scss";

.deposit-specific-cra-btn {
    color: $white;
    width: 240px;
    height: 37px;
    background-color: $duckColor !important;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 37px;
    letter-spacing: 0.4px;
    border-radius: 50px;
    margin-top: 23px;
    margin-right: 15px;
    display: inline-block;
    cursor: pointer;
}
