@import "modules/variables.scss";
@import "modules/mixins.scss";

.lock-period-lb {
    line-height: initial;
    text-align: left;
    padding: 25px;

    > .lock-period-title {
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        margin: 10px 0;
    }

    > .lock-period-to-be-locked {
        font-weight: 600;
        padding-bottom: 25px;
    }

    > .lock-period-recap {
        padding-bottom: 35px;

        > .recap-row {
            line-height: 30px;

            > .recap-label,
            > .recap-amount {
                display: inline-block;
            }

            > .recap-label {
                width: 360px;
            }

            > .recap-amount {
                width: 50px;
                text-align: right;
                padding-right: 3px;
            }
        }

        > .recap-total {
            margin-left: 360px;
            border-top: 1px solid;
            width: 50px;
            text-align: right;
            padding-right: 3px;
            line-height: 30px;
        }
    }

    > .lock-period-warning {
        color: $corail;
        font-weight: 700;
        margin-bottom: 25px;
        @include pie-clearfix();

        > .warning-icon {
            float: left;
            font-size: 34px;
            margin-right: 15px;
        }
    }

    > .lock-period-comment {
        > .comment-label {
            margin-bottom: 25px;
        }

        > .comment-input {
            width: 100%;
            min-height: 55px;
            resize: none;
            font-family: $mainFont;
        }
    }

    > .lock-period-actions {
        margin-top: 20px;
        text-align: center;
        display: flex;
        justify-content: space-evenly;

        > .cancel-btn {
            // display: inline-block;
            background-color: $corail;
        }

        > .next-btn {
            // display: inline-block;
        }
    }
}
