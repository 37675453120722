@import "modules/variables.scss";
@import "modules/mixins.scss";

.status-notif {
    background-color: #f2f2f2;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include onMobile {
        flex-direction: column;
    }

    &.status-notif-success {
        background-color: rgba($acceptColor, 0.35);
    }

    &.status-notif-info {
        background-color: rgba($activityColor, 0.35);
    }

    &.status-notif-error {
        background-color: rgba($vacationColor, 0.35);
    }

    button {
        background-color: #fff;
        border: none;
        height: 30px;
        border-radius: 5px;
        padding: 0 15px;

        @include onMobile {
            margin-top: 15px;
        }
    }
}

.notif-hours-form-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;

    .parameters-card {
        max-width: 360px;
        padding: 20px 20px 0 20px;
        border-radius: 8px;

        @include onMobile {
            padding: 20px;
        }
    }
}

.parameters-card {
    width: 100%;
    height: fit-content;
    line-height: 1.2;

    .slider-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .slider-label {
            order: 1;
            font-size: 16px;
        }

        .slider {
            order: 2;
            height: 24px;
            top: auto;
        }
    }

    .notif-hours-form {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 360px;
    }

    .config-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.disable-until {
    position: relative;

    input[type=date] {
        position: absolute;
        width: 0;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0 !important;
    }
}

.config-separator {
    height: 1px;
    background-color: #e4e4e7;
    margin: 15px 0;
}

.config-value {
    font-size: 13px;
    color: $activityColor;
}

.config-disabled {
    opacity: 0.4;
}

.install-app {
    ol {
        padding-left: 25px;

        li {
            margin: 8px 0;
            line-height: 20px;

            img {
                display: inline-block;
                vertical-align: middle;
                height: 22px;
            }
        }
    }
}
