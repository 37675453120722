@import "modules/variables.scss";
@import "modules/mixins.scss";

.lb-w1200 {
    .lb-extra-activities {
        line-height: initial;
        height: initial;
        text-align: left;
        padding-bottom: 5px;

        .lb-extra-activities-header {
            height: 70px;
            line-height: 70px;
            width: 100%;
            background-color: $duckColor;
            color: $white;
            text-transform: uppercase;
            padding-left: 26px;
            position: relative;

            .extra-activities-header-title {
                position: absolute;
                font-weight: 700;

                @include onMobile {
                    text-wrap: nowrap;
                }
            }

            .extra-activities-month-header {
                position: absolute;
                display: inline-block;
                left: calc(50% - 50px);

                .month {
                    font-weight: 700;
                }

                .year {
                    font-weight: 400;
                }
            }

            .close-lb {
                position: absolute;
                right: 15px;
                top: 15px;
                font-size: 42px;
                cursor: pointer;
            }
        }

        .lb-extra-activities-content {
            margin: 15px 0 15px 30px;
            position: relative;

            @include onMobile {
                display: none;
            }

            .warning-message {
                color: $corail;
                font-size: 14px;
            }

            .extra-activities-warning {
                font-size: 15px;
            }

            .extra-activities-message {
                width: 700px;
                display: inline-block;
            }

            .delete-extra-activities-btn {
                display: inline-block;
                border: 1px solid $blueSynchrone;
                border-radius: 150px;
                width: 180px;
                height: 36px;
                font-size: 15px;
                text-align: center;
                font-weight: 400;
                line-height: 36px;
                cursor: pointer;
                position: relative;
                left: 250px;

                i {
                    font-size: 18px;
                }
            }
        }

        .extra-activities-form-container {
            margin: 15px 0 15px 30px;

            @include onMobile {
                margin: 20px;
                padding-bottom: 290px;

                &.with-overlay {
                    &:before {
                        content: "";
                        display: block;
                        position: fixed;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: rgba(23, 49, 58, 0.7);
                        z-index: 10000;
                    }
                }

                .extra-day-overlay {
                    display: block;
                    position: fixed;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: rgba(23, 49, 58, 0.7);
                    z-index: 10000;
                }

            }

            .extra-activities-form-header-sm {
                margin-left: 110px;
            }

            .extra-activities-form-header-lm {
                margin-left: 185px;
            }

            .extra-activities-form-header {
                @include onMobile {
                    display: none;
                }

                .header-container {
                    color: white;
                    font-size: 14px;
                    font-weight: 400;
                    font-style: italic;

                    div[class^="header-"] {
                        display: inline-block;
                        height: 40px;
                        padding: 5px;
                        vertical-align: middle;
                    }

                    .header-mission {
                        background-color: $duckColor;
                        width: 135px;
                        margin-right: 75px;
                        line-height: 15px;
                    }

                    .header-left {
                        width: 190px;
                        margin-right: 6px;
                        //margin-left: 6px;
                        line-height: 15px;
                    }

                    .header-center {
                        width: 125px;
                        margin-right: 6px;
                        line-height: 15px;
                    }

                    .header-total {
                        width: 50px;
                        line-height: 30px;
                    }
                }

                .hours-not-worked-header {
                    display: inline-block;
                    margin-right: 20px;

                    .header-left {
                        background-color: $newHoursNotWorked;
                    }

                    .header-center {
                        background-color: $newHoursNotWorked;
                    }

                    .header-total {
                        background-color: $newHoursNotWorked;
                    }
                }

                .intervention-header {
                    display: inline-block;
                    margin-left: 55px;

                    .no-margin-left {
                        margin-left: 0 !important;
                    }

                    .header-left {
                        background-color: $newIntervention;
                    }

                    .header-center {
                        background-color: $newIntervention;
                    }

                    .header-total {
                        background-color: $newIntervention;
                    }
                }
            }

            .no-margin-left {
                margin-left: 0 !important;
            }

            .no-complementaries-with-constraints {
                margin-left: 186px !important;
            }

            .extra-activities-form-line {
                display: flex;

                @include onMobile {
                    flex-direction: column;
                }

                //&:nth-child(even) {background: #CCC}

                > .label {
                    width: 110px;
                    line-height: 40px;
                    text-transform: capitalize;

                    @include onMobile {
                        width: auto;
                        text-align: center;
                    }
                }

                //> .overtime-day-line:nth-of-type(3) {
                //  margin-left: 6px;
                //}

                .no-input-extra-activities-mission {
                    width: 135px;
                    display: inline-block;
                    height: 40px;
                    line-height: 40px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .no-input-extra-activities-hour {
                    width: 80px;
                    text-align: center;
                }

                .no-input-hour-input {
                    width: 190px;
                }

                .hour-input-spacer {
                    width: 75px;
                    height: 40px;
                    float: left;
                }

                > .overtime-day-line {
                    display: flex;

                    @include onMobile {
                        flex-direction: column;
                    }
                    //height: 29px;

                    .fake-input-overtime-hour {
                        width: 190px;
                        //margin-left: 6px;
                    }

                    .fake-input-overtime-overtime {
                        width: 125px;
                        margin-left: 6px;
                    }

                    .fake-input-overtime-label {
                        width: 75px;
                        padding-top: 10px;
                        padding-left: 10px;
                    }

                    .fake-input-overtime-mission {
                        width: 135px;
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        float: left;
                        word-break: break-all;
                    }

                    .total-hours-container {
                        display: flex;
                        margin-left: 6px;
                        width: 50px;
                        align-items: center;

                        @include onMobile {
                            width: 100%;
                            justify-content: flex-end;
                            margin-top: 10px;

                            &:before {
                                content: "Total :";
                                font-size: 14px;
                                font-weight: bold;
                                margin-right: 5px;
                            }
                        }

                        .total-hours-container-value {
                            display: flex;
                            height: 100%;
                            align-items: center;
                            justify-content: center;
                            border-left: 2px solid $hoursNotWorked;

                            @include onMobile {
                                color: #fff;
                                font-size: 14px;
                            }
                        }

                        > .total-hours-container-value {
                            width: 48px;
                            text-align: center;
                            background: #EF753F33;

                            @include onMobile {
                                height: auto !important;
                                border: none !important;
                                background: $newHoursNotWorked;
                            }
                        }
                    }

                    .constraint-total-hours-container {

                        > .total-hours-container-value {
                            //height: 20px;
                            border-left: 2px solid $newIntervention;
                            background-color: rgba(84, 177, 181, 0.3) !important;

                            @include onMobile {
                                background-color: $newIntervention !important;
                            }
                        }
                    }

                    .extra-activities-mission {
                        width: 135px;
                        height: 40px;
                        border-top: none;
                        border-right: none;
                        border-left: 1px solid #979797;
                        border-left: none;

                        @include onMobile {
                            width: auto;
                            margin-top: 15px;
                            margin-bottom: 15px;
                        }
                    }

                    .hour-line-container {
                        float: left;
                        //border-right: 2px solid $hoursNotWorked;
                        padding: 0;

                        .extra-activities-code {
                            width: 125px;
                            order: 3;
                            height: 40px;
                            margin-left: 6px;
                            border-top: none;
                            border-right: none;
                            border-left: 1px solid #979797;
                            border-left: none;
                        }

                        .no-input-extra-activities-code {
                            width: 125px;
                            order: 3;
                            height: 40px;
                            margin-left: 6px;
                            border-top: none;
                            border-right: none;
                            border-left: 1px solid #979797;
                            border-left: none;
                            line-height: 40px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        .hour-line {
                            display: flex;
                            height: 40px;
                            align-items: center;

                            &:hover {
                                background-color: #f4f4f4;

                                input[type="time"] {
                                    background-color: #f4f4f4;
                                }

                                > .extra-activities-code {
                                    background-color: #f4f4f4;
                                    height: 40px;
                                }

                                > .hour-input-actions {
                                    opacity: 1;
                                    align-items: center;
                                }
                            }

                            > .hour-input {
                                display: inline-flex;
                                order: 2;
                                height: 40px;
                                align-items: center;
                                //background-color: red;
                                //margin-right: 6px;

                                @include onMobile {
                                    .start-end-mobile {
                                        display: none;
                                    }
                                }

                                > input[type=time] {
                                    height: 40px;

                                    @include onMobile {
                                        width: 50px;

                                        &::-webkit-calendar-picker-indicator {
                                            display: none;
                                        }
                                    }
                                }

                                > .hour-separator {
                                    width: 30px;
                                    display: inline-block;
                                    text-align: center;

                                    @include onMobile {
                                        &:before {
                                            @include font-awesome("\f105");
                                        }
                                        //display: none;
                                    }
                                }
                            }

                            > .fake-hour-input {
                                display: inline-flex;
                                order: 2;
                                align-items: center;
                                //background-color: red;
                                margin-right: 6px;
                                width: 390px;
                            }
                        }

                        .hour-input-actions {
                            display: inline-flex;
                            order: 1;
                            justify-content: space-evenly;
                            width: 75px;
                            text-align: center;
                            opacity: 0;

                            @include onMobile {
                                order: 3;
                                opacity: 1;
                            }

                            i {
                                position: relative;
                                top: 3px;
                                display: inline-block;
                                cursor: pointer;
                            }


                            .fa-plus-circle {
                                font-size: 20px;
                            }
                        }
                    }

                    > .label {
                        text-transform: capitalize;
                        font-weight: 700;
                        width: 200px;
                        float: left;
                    }

                    > .ticket-input-container {
                        display: inline-block;
                        border-left: 2px solid $hoursNotWorked;
                        padding-left: 10px;

                        /* Chrome, Safari, Edge, Opera */
                        > .ticket-input::-webkit-outer-spin-button,
                        > .ticket-input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        /* Firefox */
                        > .ticket-input[type="number"] {
                            -moz-appearance: textfield;
                        }
                    }

                    .ticket-input,
                    input[type="time"] {
                        border: none;
                        border-bottom: 1px solid #979797;
                        font-family: $mainFont;
                        font-size: 15px;
                        color: #4a4a4a;
                        width: 80px;
                        text-align: center;
                    }

                    input[type="time"]::-webkit-inner-spin-button,
                    input[type="time"]::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        margin: 0;
                    }

                    input[type="time"]::-webkit-clear-button {
                        -webkit-appearance: none;
                    }
                }
            }

            .extra-activities-comment-label {
                font-size: 15px;
                height: 20px;
                line-height: 20px;
                font-weight: 700;
                color: $blueSynchrone;
                text-align: left;
                margin-bottom: 11px;
            }

            .extra-activities-comment {
                background-color: #f4f4f4;
                color: $blueSynchrone;
                outline: none;
                border: none;
                font-family: "Open Sans";
                font-weight: 00;
                width: calc(100% - 26px);
                min-height: 80px;
                font-size: 12px;
                resize: none;

                @include onMobile {
                    width: calc(100vw - 40px);
                }
            }

            .overtime-day-total-line {
                display: flex;
                font-weight: 700;
                font-size: 16px;
                height: 40px;
                margin-top: 10px;

                @include onMobile {
                    height: auto;
                    flex-wrap: wrap;
                    font-size: 13px;
                    align-items: center;
                    justify-content: space-between;
                    gap: 5px;
                }

                .overtime-day-total-label-complementaries-small {
                    width: 375px;
                    padding-top: 10px;

                    @include onMobile {
                        width: 80%;
                        padding-top: 0;
                        margin: 0;
                    }
                }

                .overtime-day-total-label-complementaries-large {
                    width: 410px;
                    padding-top: 10px;
                    margin-left: 231px;
                    text-align: right;

                    @include onMobile {
                        width: 80%;
                        padding-top: 0;
                        margin: 0;
                    }
                }

                .overtime-day-total-label-constraints {
                    width: 69px;
                    padding-top: 10px;
                    padding-left: 10px;
                }

                .overtime-day-total-label-constraints-small {
                    text-align: right;
                    width: 265px;

                    @include onMobile {
                        width: 80%;
                        padding-top: 0;
                        margin: 0;
                    }
                }

                .overtime-day-total-label-constraints-large {
                    padding-top: 10px;
                    margin-left: 76px;
                    text-align: right;

                    @include onMobile {
                        width: 80%;
                        padding-top: 0;
                        margin: 0;
                    }
                }

                //.overtime-day-total-hour-complementaries {
                //  width: 265px;
                //  margin-left: 6px;
                //}

                .overtime-day-total-mission-complementaries {
                    width: 94px;
                    margin-left: 6px;
                }

                .overtime-day-total-overtime-complementaries {
                    width: 125px;
                    margin-left: 6px;
                }

                .overtime-day-total-hour-constraints {
                    width: 190px;
                    margin-left: 6px;
                }

                .overtime-day-total-overtime-constraints {
                    width: 125px;
                    margin-left: 6px;
                }

                .overtime-day-total-complementaries {
                    background-color: $newHoursNotWorked;
                    color: white;
                    width: 50px;
                    padding-top: 10px;
                    text-align: center;
                    margin-left: 6px;

                    @include onMobile {
                        height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 0;
                    }
                }

                .overtime-day-total-constraints {
                    background-color: $newIntervention;
                    color: white;
                    width: 50px;
                    padding-top: 10px;
                    text-align: center;
                    margin-left: 6px;

                    @include onMobile {
                        height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 0;
                    }
                }
            }
        }
    }
}


.no-hover {
    &:hover {
        background-color: unset;
    }
}

@include onMobile {
    .extra-form-bottom {
        background-color: white;
        width: 100vw;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 0 20px;
        z-index: 10000;
    }

    .lb-confirm-send-actions {
        margin: 10px !important;
    }

    .extra-day-selector {
        display: flex;
        position: absolute;
        top: -16vw;
        gap: 2vw;
        width: 100vw;
        margin-left: -20px;
        padding: 2vw  2vw 0 2vw;

        .day-btn-container {
            position: relative;
        }

        .day-btn {
            font-size: 14px;
            position: relative;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 12vw;
            height: 12vw;
            border-radius: 50%;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
            z-index: 1;

            &.disabled {
                background-color: $disabledColor;
                color: #000 !important;
                opacity: 1 !important;
            }

            &.day-btn-complementary {
                background-color: $newHoursNotWorked;
                //color: white;
            }

            &.day-btn-constraint {
                background-color: $newIntervention;
                //color: white;
            }
        }

        .activity-btn-container {
            display: flex;
            flex-direction: column;
            gap: 2vw;
            position: absolute;
            bottom: 14vw;
            opacity: 0;
            transform: translateY(20px);
            visibility: hidden;
            transition-duration: 300ms;
            z-index: 0;

            &.opened {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
            }

            .day-btn-with-label {
                position: relative;
                display: flex;
                align-items: center;
                gap: 10px;

                .day-btn-label {
                    position: absolute;
                    font-size: 14px;
                    color: #fff;
                    left: 14vw;
                }
            }

            &.rtl {
                .day-btn-with-label {
                    .day-btn-label {
                        text-align: right;
                        left: auto;
                        right: 14vw;
                    }
                }
            }
        }
    }
}
