@import "modules/variables.scss";
@import "modules/mixins.scss";

.person-row-absence-details-mobile {
    .absence-line-mobile {
        background-color: $mobileAbsenceValidationColor;
        padding: 10px 25px 10px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .absence-line-code-description {
            font-size: 15px;
            //color: $blueSynchrone;
            font-weight: 700;

            .absence-line-days-count {
                color: $greyDark;
                margin-left: 5px;
                font-size: 3vw;
            }
        }
    }
}

.validation-mobile-actions {
    display: flex;
    justify-content: space-between;
    width: 75vw;

    .base-btn {
        border: none;
        border-radius: 50px;
        height: 5vh;
        width: 30vw;
        cursor: pointer;
        font-family: $mainFont;
        text-align: center;
        font-weight: 600;
        font-size: 4vw;
    }

    .validate-btn {
        background-color: $acceptColor;
        color: white;
    }

    .refuse-btn {
        background-color: $refuseColor;
        color: white;
    }

    .disabled-btn {
        background-color: $disabledColor;
    }
}

.validation-overlay {
    //padding: 10vh 12vw 5vh 12vw;
    color: $blueSynchrone;
    justify-content: flex-start;
    text-align: center;


    .absence-validation-mobile-header {
        font-size: 18px;
        margin: 15px 0;
        font-weight: 500;
    }

    .absence-validation-mobile-event-code {
        color: $vacationColor;
        font-size: 18px;
        //margin: 15px 0;
        text-transform: uppercase;
        font-weight: 700;
    }

    .validate-absence-mobile-comment-input {
        height: 10vh;
        width: 80vw;
        margin: 25px 0;
        font-family: $mainFont;
    }

    .validate-absence-mobile-comment-input::placeholder {
        font-size: 4vw;
        font-family: $mainFont;
    }
}
