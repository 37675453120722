@import "modules/variables.scss";
@import "modules/mixins.scss";

.event {
    height: 20px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 12px;
    font-family: $mainFont;
    color: $eventCodeDescription;
    padding: 0 5px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.view-only {
        cursor: initial !important;
    }

    > .description {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.sick-event {
    background-color: rgb(165, 17, 96);
    color: white;
    border: none !important;

    .description {
        float: left;
        line-height: 20px;
        margin-right: 10px;
    }

    .sick {
        height: 20px;
        width: 1100px;
        display: inline-block;
        background-image: url("/img/sickness.png");
    }
}
