@import "modules/variables.scss";
@import "modules/mixins.scss";

.remote-working-btn {
    background-color: white !important;
    border: 2px solid $duckColor;
    color: $duckColor;
    width: 250px;
    height: 40px;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 37px;
    letter-spacing: 0.4px;
    border-radius: 50px;
    margin-top: 10px;
    margin-right: 15px;
    cursor: pointer;
    font-weight: 700;

    i {
        margin-right: 8px;
    }
}

.remote-work-editing-btn {
    background-color: $green !important;
    border-color: $green !important;
    color: white !important;
    display: inline-block;
}
