@import "modules/variables.scss";
@import "modules/mixins.scss";

.share-block {
    position: absolute;
    top: 300px;
    right: calc((100vw - 1040px) / 2);

    .share-icon {
        color: blueSynchrone;
        width: 45px;
        height: 45px;
        font-size: 40px;
        line-height: 45px;
        margin: 10px;
    }

    .share-media {
        //margin: 15px 0;
        text-align: right;
        background-color: white;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 10px;
        border-top: 1px solid #a6b9c5;
        border-right: 1px solid #a6b9c5;
        border-bottom: 1px solid #a6b9c5;
        // @include pie-clearfix();

        a {
            display: block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background-color: $blueSynchrone;
            color: $white;
            font-size: 30px;
            margin: 5px 3px 15px 3px;
            border-radius: 8px;
            vertical-align: middle;

            &#share-linkedin {
                background-color: $green;
            }

            &#share-twitter {
                background-color: $blueLightSynchrone;
            }

            &#share-viadeo {
                background-color: $red;
            }

            &#share-facebook {
                background-color: $career;
                text-align: right;
                margin: 5px 3px;
                i {
                    font-size: 36px;
                    vertical-align: bottom;
                    margin-bottom: -2px;
                    margin-right: 5px;
                }
            }

            &#share-mail {
                background-color: $corail;
                font-size: 24px;
                margin: 5px 3px;
            }
        }
    }
}
