@import "modules/variables.scss";
@import "modules/mixins.scss";

.forgot-password-text {
    color: #fff;
    margin: 0 0 50px 0;
}

.disabled {
    color: $eventCodeDescription !important;
}

@media (max-width: 599px) {
    #login-container {
        min-height: 100%;
        height: auto;

        &::before {
            min-height: 100%;
            height: auto;
        }
    }

    #disclaimer:not(.login-disclaimer) {
        position: relative !important;
        padding: 15px 0 !important;
        bottom: 0 !important;
    }

    #form-forgot-password-container {
        text-align: center;
        position: relative !important;
        padding-top: 30px;
        transform: none !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;

        .forgot-password-splitter {
            display: flex;
            flex-direction: column-reverse;

            .form-forgot-password {
                width: 100%;

                #password, #confirmPassword {
                    margin: 50px 0 0 0 !important;
                }

                .next-btn {
                    margin: 50px auto 0 auto !important;
                }
            }

            .validation-checks {
                width: 90% !important;
                margin: 50px auto 0 auto !important;
            }
        }
    }
}

#form-forgot-password-container,
.form-forgot-password-container {
    position: relative;
    z-index: 50;
    //top: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //transform: translate(0, -50%);

    #confirm-reset-password-hidden-indicator,
    #reset-password-hidden-indicator {
        color: white;
        position: relative;
        right: 35px;
    }

    img {
        // text-align: center;
        width: 434px;
        margin: 0 auto 50px auto;
        display: block;

        @include onMobile {
            width: 64vw;
        }
    }

    .forgot-password-header {
        text-align: center;
        color: white;
        font-size: 22px;
    }

    form {
        display: inline-block;
        width: 50%;

        .password-valid {
            color: $notifierSuccessColor;
            display: inline-block;
            font-size: 18px;
        }

        #password,
        #confirmPassword {
            width: 250px;
            display: inline-block;
            margin-left: calc(100% - 300px);
            margin-bottom: 50px;
            margin-right: 10px;
            border-bottom: 1px solid $white;

            &:focus {
                outline: none;
            }
        }

        .next-btn {
            display: block;
            width: 250px;
            margin-left: calc(100% - 300px);

            &.disabled {
                color: $eventCodeDescription;
            }
        }
    }

    .validation-checks {
        width: 380px;
        margin-top: 30px;
        margin-left: 50px;
        display: inline-block;
        color: $blueSynchrone;
        background-color: $autofilled;
        padding: 10px 25px 20px 25px;
        font-size: 14px;

        .validation-check-header {
            text-align: left;
            font-size: 14px;
            font-weight: 600;
            margin: 15px 0;
        }

        .validation-check {
            text-align: left;
            line-height: 22px;
        }

        .validation-icon {
            color: $eventCodeDescription;
            font-size: 18px;
        }

        .validated {
            > .validation-icon {
                color: $notifierSuccessColor;
            }
        }
    }
}
