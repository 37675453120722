@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

//.harassment-item {
//    background-color: #f0f9f8;
//    padding: 0 12px;
//    width: 98%;
//    margin: 0 auto;
//
//    .contact-content {
//        height: 24px !important;
//        line-height: 24px;
//
//        .contact-name,
//        .contact-email {
//            display: inline-block;
//            font-size: 12px;
//        }
//
//        .contact-name {
//            width: 65%;
//            font-weight: 600;
//        }
//
//        .contact-email {
//            color: $blueSynchrone;
//            font-weight: 400;
//
//            i {
//                width: 20px;
//            }
//        }
//    }
//}

.bold-title {
    font-size: 15px;
    font-weight: 600;
}

.harassment-item {
    background-color: #f0f9f8;
    font-size: 12px;
    width: 98%;
    margin: 0 auto;
    padding: 12px;
    line-height: 35px;

    .harassment-type,
    .harassment-title {
        font-weight: 600;
        display: inline-block;
    }

    .harassment-contact {
        display: flex;
        line-height: normal;
        margin-bottom: 10px;

        .harassment-phones {
            display: flex;
            padding: 5px 0;
            font-weight: 400;
            width: 40%;
            align-items: center;
            border-right: 1px solid $documentsColor;

            .harassment-phones-content {
                width: 185px;
                margin-left: 8px;
            }

            .fa-phone {
                transform: rotateY(180deg);
                width: 25px;
                font-size: 15px;
            }

            .harassment-phone {
                display: inline-block;
            }
        }

        .harassment-emails {
            display: flex;
            margin-left: 8px;
            font-weight: 400;
            width: 60%;
            align-items: center;

            .harassment-emails-content {
                margin-left: 5px;
                width: 100%;
                //width: 280px;

                .harassment-email {
                    display: inline-block;
                    color: $blueSynchrone;
                    max-width: calc(100% - 25px);
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            i {
                font-size: 15px;
                min-width: 25px;
            }
        }
    }
}
