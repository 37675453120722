// .mini-loader {
//     position: relative !important;
// }

// .mini-loader div {
//     border: 2px solid $blueSynchrone;
//     border-color: $blueSynchrone transparent transparent transparent;
// }

.component-loader {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 20px;
    vertical-align: top;
}

.component-loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 4px;
    border-width: 4px;
    border-style: solid;
    border-radius: 50%;
    animation: component-loader 1.2s cubic-bezier(0.1, 0, 0.5, 1) infinite;
}

.component-loader div:nth-child(1) {
    animation-delay: -0.45s;
}

.component-loader div:nth-child(2) {
    animation-delay: -0.3s;
}

.component-loader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes component-loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
