@import "modules/variables.scss";
@import "modules/mixins.scss";

.activity-container {
    padding-left: 25px;

    > .activity-header {
        color: #9b9b9b;
        font-weight: 700;
        margin: 20px 0;
        @include pie-clearfix();

        > .header-label {
            float: left;
            width: 400px;
        }

        > .header-days-range {
            float: left;
            width: 200px;
        }

        > .header-total-days {
            float: left;
            width: 200px;
        }
    }

    > .activity-lines {
        > .activity-line {
            > .activity-line-description {
                margin-top: 8px;
            }

            > .activity-line-description,
            > .activity-edit-label {
                float: left;
                width: 400px;

                > .activity-edit-type {
                    margin-right: 10px;
                }
            }

            > .activity-line-days-range {
                float: left;
                width: 200px;
                margin-top: 8px;
            }

            > .activity-line-total-days {
                float: left;
                width: 200px;
                margin-top: 8px;
                padding-left: 40px;
            }

            .activity-line-actions {
                @include pie-clearfix();
                font-size: 26px;

                .close-edit-activity {
                    color: $notifierErrorColor;
                    cursor: pointer;
                    margin-right: 15px;
                }

                .validate-edit-activity {
                    color: $notifierSuccessColor;
                    cursor: pointer;
                }

                .edit-activity-icon {
                    color: #9b9b9b;
                    cursor: pointer;
                    position: relative;
                }
            }

            .activity-validation-comment-label {
                margin: 10px 0;
            }

            .activity-validation-comment {
                resize: none;
                font-family: $mainFont;
                width: 390px;
                height: 50px;
                margin-bottom: 20px;
            }
        }
    }
}
