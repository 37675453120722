@import "modules/variables.scss";
@import "modules/mixins.scss";

.person-row-mobile {
    .open-indicator {
        transform: none;
        transition: transform 250ms;
    }
}

.open-row {
    .open-indicator {
        transform: rotate(90deg);
        transition: transform 500ms;
    }
}

.absence-dates-mobile {
    border-left: 7px solid $vacationColor;
    padding: 5px;
}