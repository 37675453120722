@import "modules/variables.scss";
@import "modules/mixins.scss";

.press-contact {
    clear: both;
    width: 350px;
    //height: 292px;
    @include aspect-ratio(336, 292);
    background-color: #bfbcba;
    color: $blueSynchrone;
    margin: 30px auto 0 auto;
    text-align: right;
    background: url("https://www.synchrone.fr/img/actu/contact-presse.jpg");
    background-repeat: no-repeat;
    background-size: contain;

    .content {
        padding: 100px 15px 15px 15px;
        font-size: 15px;
    }

    .share-media {
        margin: 15px 0;
        text-align: right;

        a {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background-color: $blueSynchrone;
            color: $white;
            font-size: 30px;
            margin: 5px 3px;
            border-radius: 8px;
            vertical-align: middle;

            &#share-linkedin {
                background-color: $green;
            }

            &#share-twitter {
                background-color: $blueLightSynchrone;
            }

            &#share-viadeo {
                background-color: $red;
            }

            &#share-facebook {
                background-color: $career;
                text-align: right;
                i {
                    font-size: 36px;
                    vertical-align: bottom;
                    margin-bottom: -2px;
                    margin-right: 5px;
                }
            }

            &#share-mail {
                font-size: 24px;
            }
        }
    }
}
