@import "modules/variables.scss";
@import "modules/mixins.scss";

.extra-activity-container {
  border: 1px solid $eventCodeDescription;
  margin-right: 15px;

  .extra-activities-validation-type, .extra-activity-container-total {
    font-size: 14px;
    padding-left: 10px;
    font-weight: 700;
  }

  .extra-activity-mission {
    font-size: 14px;
    width: 385px;

    > div {
      width: 385px;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      overflow: hidden;
    }
  }

  .extra-activity-mission-no-overflow, .extra-activity-label-no-overflow {
    > div {
      overflow: unset !important;
    }
  }

  .extra-activities-validation-type.complementaries-type, .extra-activity-container-total.complementaries-type {
    color: $newHoursNotWorked;
  }

  .extra-activities-validation-type.constraints-type, .extra-activity-container-total.constraints-type {
    color: $newIntervention;
  }

  .header-extra-activity-label {
    width: 280px;
    text-align: left !important;
  }

  .header-mission {
    min-width: 370px;
    text-align: left !important;
  }

  .header-date {
    min-width: 100px;
    text-align: left !important;
  }

  .header-extra-activity-spacer {
    min-width: 20px;
  }

  .header-start-at {
    text-align: left !important;
    min-width: 95px;
  }

  .header-end-at {
    text-align: left !important;
    min-width: 95px;
  }

  .header-type {
    min-width: 80px;
    text-align: left !important;
    padding-left: 10px;
  }

  .header-extra-activity-actions {
    min-width: 180px;
  }

  .header-hour-amount {
    min-width: 80px;
    text-align: left !important;
  }

  .left-align {
    text-align: left !important;
  }

  .extra-activity-container-total {
    border-top: 1px solid $eventCodeDescription;
    padding-top: 5px;
    padding-bottom: 20px;
  }

  .colorless {
    color: black !important;
    font-weight: 400;
  }

  .extra-activity-label {
    width: 280px;

    > div {
      width: 280px;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      overflow: hidden;
    }
  }

  .extra-activity-week-comment-container {
    .extra-activity-week-comment-label {
      display: inline-block;
      color: $eventCodeDescription;
      width: 130px;
      vertical-align: top;
      font-weight: 700;
      font-size: 14px;
      padding-left: 8px;
    }

    .extra-activity-week-comment {
      display: inline-block;
      vertical-align: top;
      width: 1000px;
      font-size: 14px;
      padding: 0 15px;
    }
  }

  .extra-activity-total-line-ml-35 {
    margin-left: 35px;
  }

  .extra-activity-total-line-ml-37 {
    margin-left: 37px;
  }
}

.extra-activity-week-header {
  color: $eventCodeDescription;
  font-weight: 700;
  font-size: 14px;
  margin: 10px 0;

  .extra-activity-week-actions {
    float: right;
    font-size: 26px;
    display: inline-block;
    margin-right: 10px;

    .close-edit-extra-activities {
      color: $notifierErrorColor;
      margin-right: 10px;
    }

    .validate-edit-extra-activities {
      color: $notifierSuccessColor;
    }

    .edit-extra-activities-icon {
      cursor: pointer;
    }

    .edit-extra-activities-btn {
      color: black;
    }
  }
}
