@import "modules/variables.scss";
@import "modules/mixins.scss";

.article-type-interview {
    color: $blueLightSynchrone;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 30px;
    min-height: 32px;
    line-height: 32px;

    > i {
        font-size: 32px;
        margin-right: 25px;
    }
}

.interview-img {
    float: right;
    margin: 0 0 20px 20px !important;
}

.article-interview-name {
    font-size: 20px;
    font-weight: normal;
}

.article-interview-summary {
    display: block;
    margin: 30px 0;
    font-size: 18px !important;
    font-weight: normal !important;
}
