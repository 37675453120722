@import "modules/variables.scss";
@import "modules/mixins.scss";

.extra-activities-requests-container {
  margin-top: 90px;

  .header-extra-activity-requested-at {
    display: inline-block;
    width: 23%;
  }

  .header-extra-activity-request-for {
    display: inline-block;
    width: 25%;
  }

  .header-extra-activity-actions {
    display: inline-block;
    width: 25%;
  }

  .extra-activities-requests-list {
    margin-left: 80px;
    padding-left: 40px;

    .no-activity {
      text-align: center;
      margin-top: 25px;
      width: 1200px;
    }

    .extra-activities-requests-list-title-container {
      padding-bottom: 20px;
      display: flex;

      .extra-activities-requests-list-title {
        font-size: 32px;
        height: 70px;
        align-items: flex-end;
        display: flex;
      }

      .caption-container {
        margin-left: 60px;
        display: flex;
        align-items: flex-end;

        .caption {
          margin-right: 20px;
          display: inline-block;

          .caption-color {
            vertical-align: top;
            display: inline-block;
            height: 20px;
            width: 8px;
            margin-right: 5px;
          }

          .caption-title {
            display: inline-block;
          }
        }
      }
    }

    .extra-activities-requests-list-header {
      width: 1200px;
      color: #9b9b9b;

      .header-identity {
        display: inline-block;
        width: 23%;
        padding: 12px;
      }

      .header-activity-type {
        display: inline-block;
        width: 30%;
        padding: 12px;
      }

      .header-days-amount {
        display: inline-block;
        width: 5%;
        padding: 12px;
      }

      .header-actions {
        display: inline-block;
        width: 22%;
        padding: 12px;
      }

      .header-overtime {
        display: inline-block;
        width: 18%;
        padding: 12px;
      }
    }
  }

  .extra-activities-month-line {
    text-transform: capitalize;
    text-align: center;
  }
}