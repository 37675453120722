@import "modules/variables.scss";


.mobile-actions-container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    min-height: 50px;
    display: flex;
    //align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    padding-bottom: 15px;
    gap: 15px;
    background-image: linear-gradient(transparent, $timesheetColor);
    background-size: 200%;
    transition-duration: 500ms;
    transition-delay: 500ms;
    //background-image: linear-gradient(transparent, red);

    &.actions-hidden {
        bottom: -100px;
    }

    .actions-container {
        position: relative;
        width: calc(100% - 65px);
        height: 100%;
        min-height: 50px;
    }

    .btn-action {
        position: relative;
        display: flex;
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        background-color: #fff;
        color: $timesheetColor;
        border-radius: 25px;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        transition: transform 300ms;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);

        &.active {
            transform: rotate(315deg);
        }

        &.no-animation {
            transition-duration: 0ms;
        }
    }

    .btn-action-container {
        position: absolute;
        width: 100%;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;
        opacity: 0;
        visibility: hidden;
        transition-duration: 250ms;
        transform: translateX(-20px);

        .btn-action-label {
            padding-left: 15px;
            text-align: right;
            color: #fff;
            font-size: 14px;
        }

        &.action-visible {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
            transition-delay: 100ms;
        }

        .btn-action {
            font-size: 22px;
            color: #000;
        }
    }
}
