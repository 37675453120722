@import "modules/variables.scss";
@import "modules/mixins.scss";

.validate-activity-lb {
    line-height: initial;
    text-align: left;
    max-width: 1200px;
    padding-bottom: 25px;

    .validate-activity-header {
        padding: 0 5px 0 25px;
        height: 75px;
        background-color: $duckColor;
        color: white;
        width: 100%;

        .validate-activity-title {
            float: left;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 75px;
            // width: 72%;
            font-size: 16px;
            letter-spacing: 0.13px;
        }
    }

    .validate-activity-period {
        text-transform: capitalize;
        padding: 15px 25px;
        font-size: 20px;
        background-color: $menuColor;
        color: #fff;
        width: 100%;
        text-align: center;

        .validate-activity-period-month {
            text-transform: uppercase !important;
            font-weight: 700;
        }

        .validate-activity-period-year {
            font-weight: 100;
        }
    }

    .validate-form-no-prestation {
        color: $menuColor;
        font-weight: bold;
        padding-left: 25px;
        margin-top: 10px;
    }

    .validate-flex-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        background-color: #e9e8e9;
        width: 96%;
        margin: 25px auto 10px auto;
        padding-bottom: 35px;

        .validate-activity-counts-titles-container {
            width: 100%;
            display: flex;
            justify-content: space-evenly;

            .validate-activity-counts-title {
                width: 47%;
                color: $duckColor;
                font-weight: 700;
                font-size: 18px;
                margin: 15px 0;
            }
        }

        .validate-activity-counts-container {
            width: 47%;
            background-color: #fff;

            .validate-activity-counts-subcontainer {
                padding-bottom: 15px;

                .flex-space-evenly {
                    justify-content: space-evenly !important;
                }

                .validate-activity-counts {
                    height: 50px;
                    padding: 10px;
                    display: flex;
                    justify-content: space-between;

                    .worked-days-counter {
                        border: 2px dashed $duckColor;
                        display: inline-block;
                        width: 46%;
                        height: 52px;
                        line-height: 36px;
                        font-size: 18px;
                        text-align: center;
                        padding: 5px;
                        margin-right: 10px;
                        font-weight: 600;

                        i {
                            color: $duckColor;
                            margin-right: 12px;
                        }
                    }

                    .quota-counter {
                        border: 2px dashed $hoursNotWorked;
                        display: inline-block;
                        width: 46%;
                        height: 52px;
                        line-height: 36px;
                        font-size: 18px;
                        text-align: center;
                        padding: 5px;
                        font-weight: 600;

                        i {
                            color: $hoursNotWorked;
                            margin-right: 12px;
                        }
                    }

                    > .activity-count {
                        display: flex;
                        flex-direction: column;
                        font-weight: 700;

                        > span {
                            margin-top: 5px;
                            border-left: 6px solid $menuColor;
                            padding-left: 5px;
                        }
                    }

                    > .absence-count {
                        display: flex;
                        flex-direction: column;
                        font-weight: 700;

                        > span {
                            margin-top: 5px;
                            border-left: 6px solid $vacationColor;
                            padding-left: 5px;
                        }
                    }

                    > .total-count-information {
                        background-color: $menuColor;
                        color: $white;
                        width: 160px;
                        text-align: center;
                        font-size: 18px;
                        height: 52px;
                        line-height: 52px;

                        .total-count {
                            display: inline-block;
                            font-weight: 700;
                        }
                    }

                    .count {
                        font-weight: normal;
                    }
                }
            }
        }

        .no-background {
            background-color: unset;
        }
    }

    .validate-activity-particularities-container {
        font-size: 14px;
        padding: 10px;

        > .validate-activity-particularity {
            margin-bottom: 5px;
            font-weight: 700;

            > .icon {
                display: inline-block;
                margin-right: 10px;
                font-size: 18px;
                width: 18px;
            }

            > .label {
                display: inline-block;
            }
        }
    }

    .validate-activity-comment {
        padding: 25px;

        .validate-activity-comment-label {
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: 700;

            > i {
                font-size: 17px;
                margin-right: 5px;
            }
        }

        .validate-activity-comment-input {
            background-color: #e9e8e9;
            min-height: 80px;
            width: 100%;
            border: none;
            resize: none;
            font-family: $mainFont;
        }
    }

    .validate-activity-user-comment-container {
        padding: 25px;

        .validate-activity-user-comment {
            padding: 0 25px;
            min-width: 250px;
            display: inline-block;
        }

        i {
            color: $duckColor;
        }
    }

    .validate-activity-identity-block {
        float: right;
        padding-top: 7px;
        padding-right: 10px;

        .validate-activity-identity {
            font-size: 15px;
            font-weight: 700;
        }

        .validate-activity-person-type {
            display: inline-block;
            width: 60px;
            color: $blueSynchrone;
            background-color: #e9e8e9;
            border-radius: 9px;
            font-size: 9px;
            text-align: center;
            font-weight: 600;
            letter-spacing: 0.08px;
            line-height: 7.4px;
            margin-right: 5px;
        }

        .validate-activity-matricule {
            display: inline-block;
        }

        .validate-activity-matricule,
        .validate-activity-manager {
            font-weight: 100;
            font-size: 13px;
        }

        .fa-stack {
            font-size: 12px;

            .inside-icon {
                font-size: 14px;
            }
        }
    }

    > .validate-activity-actions {
        margin-top: 20px;
        text-align: center;
        display: flex;
        justify-content: space-evenly;

        > .cancel-btn {
            background-color: $inactiveSendMonth;
        }

        > .refuse-btn {
            @include event-type-button($corail);
        }
    }

    .duck-color {
        color: $duckColor;
    }

    .hours-not-worked-color {
        color: $hoursNotWorked;
    }

    .special-time-color {
        color: $corail;
    }

    .partial-time-color {
        color: $greyDark;
    }

    .contractor-color {
        color: $pantone;
    }
}
