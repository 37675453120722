@import "modules/variables.scss";

.sent-at-label {
    color: $blueSynchrone;
    font-style: italic;
    font-family: "Open Sans";
    font-size: 12px;
    letter-spacing: 0.1px;
    margin-top: 14px;
}
