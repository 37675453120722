@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.lb-event-form {
    .lb-event-form-type {
        font-size: 15px;
        font-weight: 800;
        text-transform: uppercase;
        display: inline;
        padding-left: 25px;

        @include onMobile {
            display: block;
            margin: 20px 0;
            padding-left: 0;
        }

        &.activity-type {
            color: $menuColor;
        }

        &.absence-type {
            color: $vacationColor;
        }

        &.hours-not-worked-type {
            color: $hoursNotWorked;
        }
    }

    .insufficient-vacations {
        color: $notifierErrorColor;
        font-size: 13px;
        font-weight: 900;
        float: right;
        margin-right: 35px;

        @include onMobile {
            display: block;
            width: 100%;
            margin: 0 0 25px 0;
        }

        > .insufficient-vacations-icon {
            font-size: 23px;
            position: relative;
            right: 9px;
            top: 4px;
        }
    }
}
