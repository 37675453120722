@import "modules/variables.scss";
@import "modules/mixins.scss";

.hour-container {
    .hour-bullet {
        height: 20px;
        width: 20px;
        border-radius: 10px;
        border: 2px solid #fdbc32;
        float: left;
        margin: 2px 5px;
        // margin-top: 5px;
    }

    .hour-label {
        float: left;
        margin: 2px 0;
        width: calc(100% - 30px);
        height: 22px;
        cursor: pointer;

        .hour-times {
            font-size: 10px;
            font-weight: 700;
            line-height: 10px;
        }

        .hour-description {
            color: #9b9b9b;
            font-size: 12px;
            line-height: 12px;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            width: 90px;
            text-overflow: ellipsis;
        }
    }
}
