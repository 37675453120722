@import "modules/variables.scss";
@import "modules/mixins.scss";

.comment-period-lb {
    line-height: initial;
    text-align: left;
    position: relative;

    > .comment-period-lb-header {
        padding: 5px 25px;
        background-color: $blueSynchrone;
        color: $white;
    }

    > .close-lb {
        @include font-awesome("\f4a6");
        cursor: pointer;
        color: $white;
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 20px;
    }

    > .comment-author {
        padding: 25px 25px 0 25px;
        font-weight: 700;
    }

    > .comment-closed-at {
        padding: 0 25px;
        font-style: italic;
        margin-bottom: 25px;
    }

    > .comment-comment {
        padding: 0 25px 25px 25px;
        // &:before {
        //     @include font-awesome("\f4a6");
        // }
    }
}
