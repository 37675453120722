@import "modules/variables.scss";
@import "modules/mixins.scss";

.lb-item-documents {
    @include pie-clearfix();
    padding-left: 85px;
    padding-bottom: 15px;
    outline: none;

    .files-container {
        height: 48px;
        width: 200px;
        margin: auto;
    }

    .dragzone {
        border: 2px dotted grey;
        font-size: 14px;
        line-height: 48px;
        height: 48px;
        cursor: pointer;

        > .file-icon {
            font-size: 30px;
            margin-right: 25px;
            margin-top: 5px;
            position: relative;
            float: left;
            top: 5px;
        }

        > .filename {
            font-size: 12px;
            float: left;
            font-weight: 600;
            line-height: 38px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 200px;
            text-align: center;
        }

        > .remove-file {
            position: relative;
            top: -13px;
        }
    }
}