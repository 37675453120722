@import "modules/variables.scss";
@import "modules/mixins.scss";

.bold {
    font-weight: 700;
}

.extra-bold {
    font-weight: 900;
}

.gdpr-header {
    > .title {
        text-align: right;
        font-size: 24px;
        margin-right: calc(50vw - calc(175px / 2));
    }

    > .logo-synchrone {
        margin-left: 120px;
    }
}

#gdpr-container {
    margin: 115px auto 50px auto;
    line-height: 21px;
    color: $blueSynchrone;
    width: 1200px;

    > .gdpr-list-title {
        padding: 20px 0;
        font-size: 32px;
    }

    > h1.main-title {
        color: $menuColor;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }

    > p,
    > .list {
        font-size: 14px;
    }

    > .list {
        > p {
            margin-left: 100px;

            > .fa-check {
                font-size: 18px;
                margin-right: 15px;
            }
        }
    }

    .privacy-mail {
        text-decoration: none;
        color: $menuColor;
        font-weight: 700;
    }

    > h2.annexe-title {
        font-weight: 700;
        color: $menuColor;
        font-size: 18px;
        margin-top: 25px;
    }

    > .annexe {
        border-collapse: collapse;
        margin-bottom: 25px;

        .header {
            > .header-col1 {
                padding: 10px;
                width: 25%;
                font-weight: 900;
                background-color: #d9d9d9;
                border: 1px solid black;
            }

            > .header-col2 {
                padding: 10px;
                width: 10%;
                font-weight: 900;
                background-color: #d9d9d9;
                border: 1px solid black;
            }

            > .header-col3 {
                padding: 10px;
                width: 15%;
                font-weight: 900;
                background-color: #d9d9d9;
                border: 1px solid black;
            }

            > .header-col4 {
                padding: 10px;
                width: 20%;
                font-weight: 900;
                background-color: #d9d9d9;
                border: 1px solid black;
            }

            > .header-col5 {
                padding: 10px;
                width: 30%;
                font-weight: 900;
                background-color: #d9d9d9;
                border: 1px solid black;
            }
        }

        .row {
            > .means {
                padding: 10px;
                border: 1px solid black;
                width: 25%;
            }

            > .data-type {
                padding: 10px;
                border: 1px solid black;
                width: 10%;
                text-align: center;
            }

            > .legal-basis {
                padding: 10px;
                border: 1px solid black;
                width: 15%;
            }

            > .duration {
                padding: 10px;
                border: 1px solid black;
                width: 20%;
            }

            > .recipient {
                padding: 10px;
                border: 1px solid black;
                width: 30%;
            }
        }
    }

    > .data-types {
        > .header {
            text-decoration: underline;
        }
    }
}
