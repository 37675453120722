@import "modules/variables.scss";
@import "modules/mixins.scss";

.days-amount-block {
    padding: 11px;
    border-right: 2px solid #f2f2f2;
    width: 5%;
    font-size: 13px;

    .days-amount-group-content {
        border-left: 1px solid #a6b9c5;
        padding-left: 10px;
    }

    .days-amount-group {
        line-height: 24px;
        margin-bottom: 18px;
    }

    .days-amount-group-total {
        font-weight: 600;
    }
}
