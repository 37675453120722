@import "modules/variables.scss";
@import "modules/mixins.scss";

.validate-absence-lb {
    line-height: initial;
    text-align: left;
    max-width: 1200px;
    padding-bottom: 25px;

    .validate-absence-header {
        padding: 0 5px 0 25px;
        height: 75px;
        background-color: $vacationColor;
        color: white;
        width: 100%;

        .validate-absence-title {
            float: left;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 75px;
            // width: 72%;
            font-size: 16px;
            letter-spacing: 0.13px;
        }

        .validate-absence-identity-block {
            // display: inline-block;
            padding-top: 7px;
            padding-right: 10px;
            float: right;

            .validate-absence-identity {
                font-size: 15px;
                font-weight: 700;
            }

            .validate-absence-person-type {
                display: inline-block;
                width: 60px;
                color: $blueSynchrone;
                background-color: #e9e8e9;
                border-radius: 9px;
                font-size: 9px;
                text-align: center;
                font-weight: 600;
                letter-spacing: 0.08px;
                line-height: 7.4px;
                margin-right: 5px;
            }

            .validate-absence-matricule {
                display: inline-block;
            }

            .validate-absence-matricule,
            .validate-absence-manager {
                font-weight: 100;
                font-size: 13px;
            }

            .fa-stack {
                font-size: 12px;

                .inside-icon {
                    font-size: 14px;
                }
            }
        }
    }

    .select-container-edit-type {
        width: calc(100% - 50px);
        margin: auto;
    }

    .validate-absence-description {
        padding: 25px;
    }

    .validate-absence-comment {
        padding: 25px;

        .validate-absence-comment-label {
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: 700;

            > i {
                font-size: 17px;
                margin-right: 5px;
            }

            .send-to-comment {
                font-weight: normal;
            }
        }

        .validate-absence-comment-input {
            background-color: #e9e8e9;
            min-height: 80px;
            width: 100%;
            border: none;
            resize: none;
            font-family: $mainFont;
        }
    }

    > .validate-absence-actions {
        margin-top: 20px;
        text-align: center;
        display: flex;
        justify-content: space-evenly;

        > .cancel-btn {
            background-color: $inactiveSendMonth;
        }

        > .refuse-btn {
            @include event-type-button($corail);
        }
    }
}
