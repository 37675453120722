@import "modules/variables.scss";
@import "modules/mixins.scss";


.absence-dates {
    @include onDesktop {
        float: left;
        width: 37%;
        height: 25px;
    }

    .absence-startAt {
        @include onMobile {
            margin-right: 8px;
        }
    }

    .absence-startAt,
    .absence-endAt {
        text-transform: capitalize;

        @include onDesktop {
            display: inline-block;
            width: 46%;
        }
    }

    .absence-endAt {
        width: 47%;
        margin-left: 10px;
    }

    .fa-chevron-right {
        font-size: 18px;
    }

    .absence-start-moment,
    .absence-end-moment {
        font-weight: 700;
        font-style: italic;
    }
}