@import "modules/variables.scss";
@import "modules/mixins.scss";

.modality-badge {
    width: 80px;
    color: white;
    background-color: $blueSynchrone;
    border-radius: 9px;
    font-size: 11px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.08px;
    line-height: 7.4px;
    margin-right: 5px;
    padding: 2px 0;
    height: 18px;
    display: inline-block;

    &.one-liner {
        line-height: 14.8px;
    }
    &.two-liner {
        line-height: 7.4px;
    }
}

.modality-one-badge {
    color: white;
    background-color: #25b9d6;
}