@import "modules/variables.scss";
@import "modules/mixins.scss";

.tutorial-thumbnail {
    img {
        margin: 20px 10px;
    }
}

.tutorial-title {
    margin-top: 15px;
    margin-bottom: 19px;
    font-family: "Open Sans", sans-serif;
    font-size: 32px;
    font-weight: normal;
    color: $blueSynchrone;
}
