@import "modules/variables.scss";
@import "modules/mixins.scss";

.overtime-actions {
    height: initial !important;
    display: block;

    .next-btn {
        position: relative !important;
        bottom: 20px !important;
        left: 0 !important;
    }
}

.step4-actions {
    line-height: initial;
    text-align: left;
    padding: 0 40px;

    @include onMobile {
        padding: 0 15px !important;
    }

    .overtime-days-container {
        margin-top: 25px;
        margin-bottom: 50px;

        > .overtime-days-container-left {
            width: calc(100% - 300px);
            float: left;

            @include onMobile {
                width: auto;
                float: none;
            }

            > .overtime-days-container-header {
                height: 28px;
                margin-left: 200px;
                font-size: 13px;
                font-style: italic;

                @include onMobile {
                    display: none;
                }

                .col1 {
                    display: inline-block;
                    width: 100px;
                }

                .col2 {
                    display: inline-block;
                    width: 100px;
                }

                .col3 {
                    display: inline-block;
                }

                .tickets-header {
                    width: 180px;
                }
            }

            > .overtime-day-line {
                clear: both;
                height: 29px;

                @include onMobile {
                    display: flex;
                    height: auto;
                    min-height: 34px;
                }

                .label {
                    float: left;
                    width: 200px;
                    text-transform: capitalize;
                    font-weight: 700;
                    margin: 2px 0;
                }

                .total-hours-container {
                    display: inline-block;
                    margin-left: 30px;

                    @include onMobile {
                        margin-left: 0;
                        display: none;
                    }
                }

                .ticket-input-container {
                    width: 120px;
                    text-align: center;
                    float: left;
                }

                .hour-line-container {
                    float: left;
                    border-right: 2px solid $hoursNotWorked;
                    margin: 2px 0;

                    @include onMobile {
                        float: none;
                        display: flex;
                        border-right: none;
                        flex-direction: column;
                    }

                    .hour-line {
                        height: 29px;

                        @include onMobile {
                            display: flex;
                        }

                        > .hour-input {
                            display: inline-block;
                            margin-right: 25px;

                            @include onMobile {
                                display: flex;
                                align-items: center;
                            }

                            > .hour-separator {
                                width: 60px;
                                display: inline-block;
                                text-align: center;
                            }
                        }
                    }

                    .hour-input-actions {
                        display: inline-flex;
                        justify-content: space-evenly;
                        width: 75px;
                        text-align: center;
                        opacity: 0;

                        i {
                            position: relative;
                            top: 3px;
                            display: inline-block;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        > .overtime-days-container-right {
            float: left;

            .hours-total,
            .constraint-total,
            .ticket-total {
                margin-bottom: 15px;

                > .label {
                    width: 140px;
                    font-size: 15px;
                    display: inline-block;
                }

                > .input-container {
                    display: inline-block;
                    background-color: $hoursNotWorked;
                    height: 50px;
                    padding: 12px 38px;

                    .input {
                        background-color: $hoursNotWorked;
                        border: none;
                        text-align: center;
                        height: 26px;
                        width: 80px;
                        font-size: 15px;
                        color: $blueSynchrone;
                        font-weight: 700;
                        font-family: $mainFont;
                    }
                }
            }
        }

        .step4-comment {
            clear: both;

            .step4-comment-label {
                font-size: 15px;
                font-weight: 700;
            }

            .step4-comment-input {
                font-family: "Open Sans";
                font-size: 14px;
                min-height: 80px;
            }
        }
    }
}
