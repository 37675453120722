@import "modules/variables.scss";
@import "modules/mixins.scss";

.cra-validations-counters {
    background-color: #9abdc5;
    padding: 15px;
    //display: flex;
    justify-content: space-evenly;
    font-size: 4vw;

    .cra-validations-counters-line {
        display: flex;
        justify-content: space-between;

        .cra-validations-counters-description {
            font-style: italic;
        }

        .cra-validations-counters-count {
            font-weight: 700;
        }
    }

}

.cra-validation-infos {
    background-color: $autofilled;
    padding: 15px;
    text-align: center;
}

.cra-validation-actions {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;

    .base-btn {
        border: none;
        border-radius: 50px;
        height: 5vh;
        width: 30vw;
        cursor: pointer;
        font-family: $mainFont;
        text-align: center;
        font-weight: 600;
        font-size: 4vw;
    }

    .validate-btn {
        background-color: $acceptColor;
        color: white;
    }

    .refuse-btn {
        background-color: $refuseColor;
        color: white;
    }

    .disabled-btn {
        background-color: $disabledColor;
    }
}

.cra-validation-mobile-header {
    font-size: 6vw;
    font-weight: 500;
}

.cra-validation-mobile-event-code {
    color: $timesheetColor;
    font-size: 5vw;
    text-transform: uppercase;
    font-weight: 700;
}

.validate-cra-mobile-comment-input {
    height: 10vh;
    width: 75vw;
    font-family: $mainFont;
}

.validate-cra-mobile-comment-input::placeholder {
    font-size: 4vw;
    font-family: $mainFont;
}

.event-group-container {
    width: 80vw;

    .event-group-line {
        text-align: left;
        margin-bottom: 5px;

        .event-group-description {
            width: 66vw;
            padding-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
            display: inline-block;
            vertical-align: bottom;
        }

        .activity-border {
            border-left: 6px solid $timesheetColor;
        }

        .absence-border {
            border-left: 6px solid $vacationColor;
        }

        .extra-activity-border {
            border-left: 6px solid $hoursNotWorkedHovered;
        }

        .event-group-days-count {
            display: inline-block;
            text-align: right;
            width: 13vw;
        }
    }
}
