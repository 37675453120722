@import "modules/variables.scss";
@import "modules/mixins.scss";

#password-hidden-indicator {
    position: relative;
    top: -30px;
    left: 225px;
    display: block;
    text-align: end;
    width: 20px;
    color: #fff;
}
