@import "modules/variables.scss";
@import "modules/mixins.scss";

#expanded-day {
    position: fixed;
    z-index: 1000;
    // left: 880px;
    // top: 670px;
    width: 200px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    min-height: 220px;
    background-color: white;
    padding: 8px 10px;
    font-size: 12px;

    .event,
    .overtime {
        margin-bottom: 10px;
    }
}

.visible-expanded-display {
    -webkit-animation: 0.2s pop-in ease-in;
    -moz-animation: 0.2s pop-in ease-in;
    -ms-animation: 0.2s pop-in ease-in;
}

.hidden-expanded-display {
    opacity: 0;
    -webkit-animation: 0.2s pop-out ease-in;
    -moz-animation: 0.2s pop-out ease-in;
    -ms-animation: 0.2s pop-out ease-in;
}

.day-number {
    font-size: 18px;
    font-family: "Montserrat";
    margin-bottom: 10px;
}

@-webkit-keyframes pop-in {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.7);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
    }
}
@-moz-keyframes pop-in {
    0% {
        opacity: 0;
        -moz-transform: scale(0.7);
    }
    100% {
        opacity: 1;
        -moz-transform: scale(1);
    }
}
@keyframes pop-in {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@-webkit-keyframes pop-out {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(0.7);
    }
}
@-moz-keyframes pop-out {
    0% {
        opacity: 1;
        -moz-transform: scale(1);
    }
    100% {
        opacity: 0;
        -moz-transform: scale(0.7);
    }
}
@keyframes pop-out {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.7);
    }
}
