@import "modules/variables.scss";
@import "modules/mixins.scss";

.absence-history-actions-lb {
    width: 1200px;
    line-height: initial;
    text-align: left;
    position: relative;

    .absence-history-actions-header {
        background-color: $vacationColor;
        color: white;
        padding: 0 30px;
        height: 75px;

        .close-lb {
            @include font-awesome("\f4a6");
            cursor: pointer;
            color: $white;
            position: absolute;
            right: 10px;
            top: 5px;
            font-size: 40px;
        }

        .absence-history-actions-title {
            float: left;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 75px;
            // width: 72%;
            font-size: 16px;
            letter-spacing: 0.13px;
        }

        .absence-history-actions-identity-block {
            float: right;
            padding-right: 10px;
            padding-top: 7px;

            .absence-history-actions-identity {
                font-size: 15px;
                font-weight: 700;
            }

            .absence-history-actions-person-type {
                display: inline-block;
                width: 60px;
                color: $blueSynchrone;
                background-color: #e9e8e9;
                border-radius: 9px;
                font-size: 9px;
                text-align: center;
                font-weight: 600;
                letter-spacing: 0.08px;
                line-height: 7.4px;
                margin-right: 5px;
            }

            .absence-history-actions-matricule {
                display: inline-block;
            }

            .absence-history-actions-matricule,
            .absence-history-actions-manager {
                font-weight: 100;
                font-size: 13px;
            }

            .fa-stack {
                font-size: 12px;

                .inside-icon {
                    font-size: 14px;
                }
            }
        }
    }

    .absence-history-actions-table-header {
        @include pie-clearfix();
        color: #9b9b9b;
        padding: 10px 30px;

        > .table-header-description {
            width: 200px;
            float: left;
        }

        > .table-header-date {
            width: 150px;
            float: left;
            padding-left: 14px;
        }

        > .table-header-old-data {
            width: 270px;
            float: left;
            padding-left: 14px;
        }

        > .table-header-new-data {
            width: 270px;
            float: left;
            padding-left: 14px;
        }

        > .table-header-author {
            width: 170px;
            float: left;
            padding-left: 14px;
        }
    }

    .absence-history-actions-content {
        padding-bottom: 30px;

        .absence-history-actions-row {
            padding: 10px 30px;
            @include pie-clearfix();

            .actions-row-description {
                width: 200px;
                border-right: 1px solid #e9e8e9;
                float: left;
            }

            .actions-row-date {
                width: 150px;
                border-right: 1px solid #e9e8e9;
                float: left;
                padding-left: 14px;
            }

            .actions-row-old-data {
                width: 270px;
                border-right: 1px solid #e9e8e9;
                float: left;
                padding-left: 14px;
            }

            .actions-row-new-data {
                width: 270px;
                border-right: 1px solid #e9e8e9;
                float: left;
                padding-left: 14px;
            }

            .actions-row-author {
                width: 250px;
                float: left;
                padding-left: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
