@import "modules/variables.scss";
@import "modules/mixins.scss";

.checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked + .checkmark:after {
        display: block;
    }
}

.checkmark {
    height: 20px;
    width: 20px;
    display: inline-block;
    position: relative;
    top: 5px;
    background-color: #eee;

    &:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        width: 6px;
        height: 12px;
        border: solid $menuColor;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
