@import "modules/variables.scss";
@import "modules/mixins.scss";

.visible-row {
    display: flex;
}

.invisible-row {
    display: none !important;
}

.open-group {
    transition: transform 0.2s;
    line-height: 34px;
}

.closed-group {
    transform: rotate(-90deg);
    transition: transform 0.2s;
    line-height: 34px;
}

.absence-counters-line {
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    text-align: left;

    > * {
        margin-right: 20px;
    }

    .absence-counter {
        color: white;
        background-color: $vacationColor;
        padding: 0 3px;
    }

    .before-june-counter {
        color: $vacationColor;
        padding: 0 3px;
        background-color: $unavailableDay;
    }

    .absence-counter-first-block {
        display: inline-block;
        //width: 250px;
    }

    .absence-counter-second-block {
        display: inline-block;
    }
}

.worked-days {
    border: 2px dashed $duckColor;
    display: inline-block;
    width: 80px;
    height: 23px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    font-weight: 600;

    i {
        color: $duckColor;
    }
}
