@import "modules/variables.scss";
@import "modules/mixins.scss";

#password-about-to-expire-pop-up-container {
    font-family: "Open Sans";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 1000000000;
    line-height: normal;

    #password-about-to-expire-pop-up {
        top: 50%;
        left: 50%;
        width: 600px;
        height: 270px;
        position: relative;
        transform: translate(-50%, -50%);
        background-color: #fff;

        #password-about-to-expire-pop-up-header {
            color: #fff;
            background-color: $duckColor;
            height: 50px;
            line-height: 50px;
            padding-left: 26px;
            text-align: left;
            text-transform: uppercase;

            .close-lb {
                position: absolute;
                right: 15px;
                top: 10px;
                font-size: 28px;
                cursor: pointer;
            }
        }

        #password-about-to-expire-pop-up-button {
            @include event-type-button($duckColor);
            width: 50%;
            margin: 25px auto;
            display: block;
        }

        #password-about-to-expire-pop-up-body {
            padding: 15px;

            .days-left {
                color: $duckColor;
                font-size: 42px;
            }

            .change-password-warning {
                color: red;
            }
        }
    }
}
