.social-item-container {
    margin-bottom: 20px;

    &:last-of-type {
        padding-bottom: 20px;
    }

    .file-row {
        width: 562px;
    }
}

.cse-item-container {
    margin-bottom: initial !important;
    padding-bottom: initial !important;
}

.insurance-item-container ~ .document-item-container {
    margin-bottom: 0 !important;
}
