@import "modules/variables.scss";
@import "modules/mixins.scss";

#news-container {
    padding-top: 90px;
    width: calc(100vw - 20px);
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f2f2f2;

    .recommandations-title {
        font-size: 20px;
        font-weight: 600;
        margin: 60px 0 25px 0;
    }

    .bricks {
        margin: 0 auto;
    }

    > .news-title {
        width: 1200px;
        margin: 120px auto 60px auto;
        font-size: 32px;

        > .fa-newspaper {
            padding-right: 50px;
        }

        @include onMobile {
            width: auto;
        }
    }

    .container {
        width: 1086px;

        @include onMobile {
            width: auto;
        }

        > .post-container {
            border: 1px solid #a6b9c5;
            margin: 150px auto 0 auto;
            border-radius: 10px;
            background-color: $white;
            width: 900px;
            padding: 75px 100px;
        }
    }

    .juicer-feed {
        width: calc(100vw - 20px);
        max-width: 1200px;
        //padding-top: 110px;
        @include onMobile {
            width: 90%;
            padding-block: 20px;
        }
    }

    @include onMobile {
        //padding-top: 80px;
        padding-top: 0;
        width: auto;
    }
}
