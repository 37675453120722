@import "modules/variables.scss";

.ask-extra-activity-btn {
    background-color: white !important;
    border: 2px solid $duckColor;
    color: $duckColor;
    width: 445px;
    height: 40px;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0.4px;
    border-radius: 50px;
    margin-top: 10px;
    margin-right: 15px;
    cursor: pointer;
    //background-color: $inactiveSendMonth;
    //cursor: not-allowed;

    i {
        margin-right: 8px;
    }

    &.active {
    }
}
