// Colors
$white: #fff;
$career: #8a1d81;
$corail: #eb5961;
$blueSynchrone: #17313a;
$blueLightSynchrone: #00c1de;
$adminHeaderColor: #e7e000;
$green: #12afa9;
$red: #e42373;
$dark: #242424;
$duckColor: #007188;
$greyDark: #878787;
$greyLight: #f2f2f2;
$grey2: #d8d4d3;
$grey: #cccccc;
$white: #fff;
$bgColor: #f2f2f2;
$hoursNotWorked: #fdbc32;
$hoursNotWorkedTransparent: rgba(253, 188, 50, 0.5);
$hoursNotWorkedHovered: #fee4ad;
$autofilled: #d4d4d4;
$autofilledTransparent: rgba(212, 212, 212, 0.5);
$eventCodeDescription: #9b9b9b;
$bluetwitter: #2b7bb9;
$pantone: #283E59;
$newHoursNotWorked: #EF753F;
$newIntervention: #5F76EE;


// Font
$mainFont: "Open Sans", sans-serif;
$light: 300;
$normal: 400;
$semiBold: 600;
$bold: 700;
$extraBold: 800;

// Layout
// $leftSideWidth: 300px;
$leftSideWidth: 660px;
$rightMenu: 54px;
$containerSize: 960px;
$navigationSize: 85px;

// Header
$headerHeight: 90px;
$hamburgerColor: #ffffff;

// Button
$buttonHeight: 40px;
$buttonFontSize: 13px;
$buttonUppercase: true;
$buttonBorderRadius: 5px;
$buttonFont: $mainFont;

// Form
$inputBorderRadius: 0;
$inputBorderColor: #d9dadb;
$inputBorderWidth: 1px;
$inputHeight: 26px;
$inputWidth: 200px;
$inputBg: #fff;
$errorColor: #ea4f35;

// Media Query
$mobileXs: 425px;
$mobile: 1000px;
$tablet: 992px;
$desktop: 1370px;

// Menu
$menuColor: #25b9d6;
$activityColor: #25b9d6;
$activityColorTransparent: rgba(37, 185, 214, 0.5);
$activityHovered: #d0ebef;

// Calendar
$unavailableDay: #ebebeb;
$inactiveSendMonth: #a6b9c5;

// Vacation count
$vacationColor: #a51160;
$vacationColorTransparent: #e4b8cf;
$vacationHovered: #d4a5be;

// Notifier
$notifierErrorColor: #f05a5a;
$notifierSuccessColor: #27b19f;

// Page
$contactColor: #a51160;
$newsColor: #007188;
$timesheetColor: #007188;
$documentsColor: #12afa9;
$rgpdColor: #ea4f35;

$mobileAbsenceValidationColor: #a5116029;
$mobileExtraActivityValidationColor: #fdbc3230;

$acceptColor: #27b19f;
$refuseColor: #eb5961;
$disabledColor: #9b9b9b;