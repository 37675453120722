@import "modules/variables.scss";
@import "modules/mixins.scss";

* {
    box-sizing: border-box;
}

body {
    background-color: #f2f2f2;
    min-height: 100%;
    min-width: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slide-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: pink;
    transform-origin: bottom;
}

.slide-out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: pink;
    transform-origin: top;
}

.hour-input {
    display: inline-block;

    @include onMobile {
        display: flex;
        align-items: center;
    }

    > .hour-separator {
        width: 120px;
        display: inline-block;
        text-align: center;

        @include onMobile {
            display: none;
        }
    }

    .start-end-mobile {
        display: none;

        @include onMobile {
            display: inline-block;
            margin: 0 5px;
            font-size: 14px;
        }
    }
}

/****** Loader *******/


.btn-loader {
    width: 32px;
    height: 32px;
    border: 3px solid var(--loader-color, #FFF);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    animation: pulse 1s linear infinite;
}

.btn-loader:after {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    border: 3px solid var(--loader-color, #FFF);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
    0% {
        transform: translate(-50%, -50%) scale(0)
    }
    60%, 100% {
        transform: translate(-50%, -50%) scale(1)
    }
}

@keyframes pulse {
    0%, 60%, 100% {
        transform: scale(1)
    }
    80% {
        transform: scale(1.2)
    }
}

.base-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2;

    .label {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 100%;
    }
}
