@import "modules/variables.scss";
@import "modules/mixins.scss";

.vacation-count-header {
    cursor: pointer;
    color: #fff;
    text-align: center;
    line-height: 69px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.11px;
    background-color: $vacationColor;
    width: 410px;
    height: 69px;
    box-shadow: 1px 1px 4px 1px rgba(122, 121, 121, 0.5);
    border-radius: 25px 25px 0 0;

    .left-vacation {
        font-size: 39px;
        float: left;
        margin-top: 15px;
        margin-left: 14px;
    }

    .right-vacation {
        font-size: 39px;
        float: right;
        margin-top: 15px;
        margin-right: 14px;
        display: none;
    }
}
