@import "modules/variables.scss";
@import "modules/mixins.scss";

.calendar-header {
    height: 40px;
}

.multiple-missions-indicator-container {
    margin-left: calc(4% - 27px);

    .multiple-missions-indicator {
        font-size: 30px;
        vertical-align: middle;
    }

    .multiple-missions-details {
        font-size: 13px;
        position: absolute;
        right: calc((100% - 1200px) / 2);
        margin-top: 15px;
        background-color: #fff;
        width: 340px;
        padding: 10px;
        box-shadow: 2px 2px 2px #d6d6d6;

        .multiple-missions-title {
            font-weight: bold;
            margin-bottom: 15px;
        }

        .mission-label {
            margin: 15px 0;

            .mission-dates {
                width: 45%;
                display: inline-block;
                vertical-align: top;
            }

            .mission-description {
                font-weight: bold;
                width: 54%;
                display: inline-block;
            }
        }
    }

    .multiple-missions-details:after{
        bottom: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .multiple-missions-details:after {
        border-bottom-color: #ffffff;
        border-width: 12px;
        left: 95.5%;
        margin-left: -12px;
    }
}
