.contact-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 auto;
    gap: 20px;
}

.contact-col {
    margin-right: 94px;
}
