@import "modules/variables.scss";
@import "modules/mixins.scss";

.calendar-not-available {
    position: fixed;
    width: 600px;
    top: 50%;
    left: 50%;
    color: #fff;
    background-color: rgb(0, 114, 136);
    height: 300px;
    margin: -150px 0 0 -300px;
    padding: 110px 50px;
    text-align: center;
}
