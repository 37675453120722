@import "modules/variables.scss";
@import "modules/mixins.scss";
@import "modules/common.scss";

.cse-item {
    .cse-item-link {
        color: $blueSynchrone;
        margin-left: 7px;
        padding: 0 10px;

        .cse-item-label {
            margin-left: 10px;
        }

        .cse-item-logo {
            height: 45px;
            float: right;
        }
    }
}
