@import "modules/mixins.scss";

.contact-header {
    color: #a51160;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.12px;
    height: 40px;
    line-height: 40px;

    @include onMobile {
        padding-left: 15px;
    }
}
