@import "modules/variables.scss";
@import "modules/mixins.scss";

#event-container {
    // border: 1px solid red;
    z-index: 100;
    position: absolute;
    width: 100%;
    top: 85px;
    margin: auto;
}
