@import "modules/variables.scss";

.absence-event-line {
    display: inline-block;
    height: 25px;
    width: calc(100% - 52px);
    margin-left: 26px;

    .absence-year {
        float: left;
        width: 4%;
        height: 25px;
    }

    .absence-total {
        float: left;
        width: 4%;
        height: 25px;
        font-weight: 700;
        text-align: right;
        margin-right: 2%;
    }

    .absence-type {
        float: left;
        width: 12%;
        height: 25px;
        font-style: italic;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .absence-created-at,
    .absence-validated-at {
        float: left;
        width: 9%;
        height: 25px;
    }

    .absence-validated-by {
        float: left;
        width: 19%;
        height: 25px;
    }
}
