@import "modules/variables.scss";
@import "modules/mixins.scss";

.vacation-totals {
    padding: 15px 20px;
    display: inline-block;

    > .vacation-totals-header {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: $blueSynchrone;
        font-family: "Open Sans";
        margin-bottom: 15px;
    }

    > .vacation-container {
        font-size: 13px;
        margin-bottom: 8px;
        min-height: 28px;

        .tooltip-container {
            position: relative;
            display: inline-block;
        }

        .toggle-infos-indicator {
            margin-left: 10px;
        }

        .jrs-infos {
            font-weight: 400;
            font-size: 13px;
            position: absolute;
            background-color: #fff;
            width: 420px;
            padding: 10px;
            //right: 285px;
            right: 20px;
            bottom: 15px;
            box-shadow: 2px 2px 2px #d6d6d6;
            line-height: 1.6;
        }

        //.jrs-infos:after {
        //  bottom: 100%;
        //  border: solid transparent;
        //  content: " ";
        //  height: 0;
        //  width: 0;
        //  position: absolute;
        //  pointer-events: none;
        //}
        //
        //.jrs-infos:after {
        //  border-bottom-color: #ffffff;
        //  border-width: 12px;
        //  left: 95.5%;
        //  margin-left: -12px;
        //}

        .container-header {
            font-weight: 700;
            line-height: 28px;
            clear: both;

            .total-name {
                float: left;
                width: 105px;
                line-height: 28px;
            }

            .two-line-total {
                line-height: 14px !important;
            }

            .total-count {
                color: white;
                background-color: $vacationColor;
                float: left;
                width: 46px;
                text-align: right;
                padding-right: 5px;
            }
        }

        .container-line {
            margin-left: 105px;
            line-height: 28px;
            clear: both;

            &:after {
                content: "";
                display: block;
                clear: both;
            }

            .line-count {
                background-color: $vacationColorTransparent;
                float: left;
                width: 46px;
                color: white;
                text-align: right;
                padding-right: 5px;
            }

            .line-description {
                margin-left: 7px;
                line-height: 27px;
                float: left;

                .vacation-before {
                    color: $notifierErrorColor;
                    font-size: 12px;
                    display: inline-block;
                }
            }
        }
    }
}
